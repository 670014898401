<template>
  <div class="q-pa-md q-my-lg">
    <div class="row justify-center">
      <div class="col col-lg-6">
        <q-card>
          <q-card-section>
            <q-form @submit="handleSubmit" class="q-pa-lg">
              <div class="row q-col-gutter-lg q-mb-md">
                <div class="col">
                  <q-input filled v-model="form.nome" label="Nome *" :error-message="error?.errors?.nome?.[0]" :error="error?.errors?.nome?.length > 0"/>
                </div>
                <div class="col">
                  <q-input filled v-model="form.sobrenome" label="Sobrenome *" :error-message="error?.errors?.sobrenome?.[0]" :error="error?.errors?.sobrenome?.length > 0"/>
                </div>
                <div class="col">
                  <q-select filled v-model="form.role.id" :options="roles" option-value="id" option-label="nome" label="Cargo *" emit-value map-options :hide-selected="rolesPending"
                  :error-message="error?.errors?.['role.id']?.[0]" :error="error?.errors?.['role.id']?.length > 0"/>
                </div>
              </div>

              <div class="row q-col-gutter-lg q-mb-md">
                <div class="col">
                  <q-input filled v-model="form.email" label="Email *" :error-message="error?.errors?.email?.[0]" :error="error?.errors?.email?.length > 0"/>
                </div>
                <div class="col">
                  <q-input filled v-model="form.password" label="Senha *" :type="isPwd ? 'password' : 'text'" :error-message="error?.errors?.password?.[0]" :error="error?.errors?.password?.length > 0">
                    <template v-slot:append>
                      <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd"/>
                    </template>
                  </q-input>
                </div>
              </div>

              <q-separator spaced="xl" />

              <div class="row justify-end">
                <q-btn label="Salvar" type="submit" color="primary" :loading="pending"/>
              </div>
            </q-form>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";

import getCollection from '@/composables/getCollection';
import useCollection from '@/composables/useCollection';
import useNotify from '@/composables/useNotify';

export default {
  setup(props, context) {
    const { pending, error, create } = useCollection('users');
    const { data: roles, load: rolesLoad, pending: rolesPending } = getCollection('roles');
    const { triggerPositive, triggerNegative } = useNotify();
    const isPwd = ref(true);

    rolesLoad();

    const initialState = reactive({
      nome: '',
      sobrenome: '',
      email: '',
      password: '',
      role: {
        id: ''
      }
    });

    const form = reactive({ ...initialState });

    const resetForm = () => {
      Object.assign(form, initialState);
    }

    const handleSubmit = async () => {
      console.log(form)
      const response = await create(form);
      if(response) {
        resetForm();
        triggerPositive('Criado com sucesso!');
      } else {
        triggerNegative('Verifique se os campos estão corretos.');
      }
    }

    return { 
      form,
      roles, rolesPending,
      pending, error,
      handleSubmit,
      isPwd
    }
  }
}
</script>