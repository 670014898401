import { render } from "./Edit.vue?vue&type=template&id=edaba730"
import script from "./Edit.vue?vue&type=script&lang=js"
export * from "./Edit.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QForm,QInput,QFile,QSelect,QItem,QItemSection,QSeparator,QBtn,QInnerLoading,QSpinner});
