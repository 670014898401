<template>
  <q-form @submit="handleSubmit" class="q-pa-lg">
    <template v-for="(apoio, index) in form.apoios" :key="index">
      <template v-if="form.apoios[index]">
        <div class="row q-col-gutter-lg q-mb-md">
          <div class="col">
            <q-input filled v-model="form.apoios[index].nome" label="Nome" :error-message="error?.errors?.[`apoios.${index}.nome`]?.[0]" :error="error?.errors?.[`apoios.${index}.nome`]?.length > 0"/>
          </div>
          <div class="col">
            <q-select filled v-model="form.apoios[index].tipo" :options="redeOptions" emit-value map-options  label="Tipo" :error-message="error?.errors?.[`apoios.${index}.tipo`]?.[0]" :error="error?.errors?.[`apoios.${index}.tipo`]?.length > 0"/>
          </div>
        </div>

        <div class="row q-col-gutter-lg q-mb-md">
          <div class="col">
            <q-input filled v-model="form.apoios[index].vinculo" label="Vínculo" :error-message="error?.errors?.[`apoios.${index}.vinculo`]?.[0]" :error="error?.errors?.[`apoios.${index}.vinculo`]?.length > 0"/>
          </div>
          <div class="col">
            <q-input filled v-model="form.apoios[index].idade" label="Idade" mask="###" :error-message="error?.errors?.[`apoios.${index}.idade`]?.[0]" :error="error?.errors?.[`apoios.${index}.idade`]?.length > 0"/>
          </div>
          <div class="col">
            <q-toggle v-model="form.apoios[index].deficiencia" label="Deficiência" />
          </div>
        </div>

        <div class="row justify-end" v-if="index != 0">
          <q-btn color="red" icon="delete" @click="removerCampos(index)"/>
        </div>

        <q-separator spaced="xl" />
      </template>
    </template>

    <div class="row justify-between">
      <q-btn color="green" label="Adicionar outro apoio" @click="adicionarCampos()"/>
      <q-btn label="Salvar" type="submit" color="primary" :loading="pending"/>
    </div>
  </q-form>
</template>

<script>
import { ref, reactive } from 'vue'

import getCollection from '@/composables/getCollection';
import useCollection from '@/composables/useCollection';
import useNotify from '@/composables/useNotify';
 const redeOptions = [
              { label: 'Familiar', value: 'familiar' },
              { label: 'Apoio', value: 'apoio' },
              
              ];
export default {
  props: ['id'],
  setup (props, context) {
    const { data, load: resourceLoad, pending: resourcePending, error: resourceError } = getCollection(`alunos/${props.id}/fichas/apoios`);
    const { pending, error, create } = useCollection(`alunos/${props.id}/fichas/apoios`);
    const { triggerPositive, triggerNegative } = useNotify();

    const initialState = {
      nome: '',
     // sobrenome: '',
     tipo:'familiar',
      vinculo: '',
      idade: '',
      deficiencia: false,
    };

    const form = reactive({
      apoios: [
        Object.assign({}, initialState)
      ]
    });

    resourceLoad().then(() => {
      Object.assign(form.apoios, data.value);
      
       if(data.value.length !== 0) {
         context.emit('check','redes');
       }
    });

    const handleSubmit = async () => {
      const response = await create(form);
      if(response) {
        triggerPositive('Salvo com sucesso!');
          context.emit('check','redes');
      } else {
        triggerNegative('Verifique se os campos estão corretos.');
      }
    }

    const adicionarCampos = () => {
      form.apoios.push(Object.assign({}, initialState));
    }

    const removerCampos = (index) => {
      form.apoios.splice(index, 1);
    }

    return {
      form, resourcePending, resourceError,
      pending, error,
      handleSubmit, adicionarCampos, removerCampos,redeOptions
    }
  }
}
</script>

<style>

</style>