import { createStore } from 'vuex';
import axios from 'axios';
import router from '@/router';

export default createStore({
  state: {
    token: localStorage.getItem('token') || null,
    error: null,
    pending: false
  },
  mutations: {
    setToken(state, data) {
      state.token = data.token;
      localStorage.setItem('token', data.token);
      router.push({ name: 'Home' });
    },
    logout(state) {
      state.token = '';
      localStorage.clear();
      router.push({ name: 'Login' });
    },
    setError(state, message) {
      state.error = message;
    },
    clearError(state) {
      state.error = null;
    },
    setPending(state, pending) {
      state.pending = pending;
    }
  },
  actions: {
    login: async ({ commit }, { email, password }) => {
      commit('setPending', true);
      try {
        const response = await axios.post(`/login`, {
          email,
          password,
        });

        commit('setToken', { 
          token: response.data.access_token, 
        });

        return response.data;
      } catch (error) {
        commit('setError', 'Senha e/ou email incorretos!');
      } finally {
        commit('setPending', false);
      }
    },
    logout: async ({ commit }) => {
      await axios.get(`/logout`).catch(() => null);
      commit('logout');
    }
  },
  getters: {
    token: state => {
      return state.token
    },
    pending: state => {
      return state.pending
    },
    error: state => {
      return state.error
    },
  },
  modules: {
  }
})
