import { render } from "./Assistencia.vue?vue&type=template&id=831c5e48"
import script from "./Assistencia.vue?vue&type=script&lang=js"
export * from "./Assistencia.vue?vue&type=script&lang=js"
script.render = render

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QToggle,QInput,QSeparator,QBtn});
