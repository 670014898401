<template>
  <q-page class="row justify-center items-center">
    <div class="col-auto">
      <q-card flat>
        <q-card-section class="text-center">
          <div class="text-h1">404</div>
          <q-separator inset class="q-my-md"/>
          <div class="text-subtitle1">Página não encontrada</div>
          <q-btn outline color="primary" label="Homepage" :to="{ name: 'Home' }" class="q-my-sm"/>
        </q-card-section>
      </q-card>
    </div>
  </q-page>
</template>

<script>
export default {

}
</script>

<style>

</style>