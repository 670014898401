import { reactive, toRefs } from "vue";
import axios from 'axios';

const getCollection = (resource) => {

    const initialState = reactive({
        data: [],
        links: null,
        meta: null,
        additional: null,
        pending: true,
        error: {
            statusCode: null,
            name: null,
            message: null
        }
    });
    
    const state = reactive({ ...initialState });

    const resetState = () => {
        Object.assign(state, initialState);
    }

    const load = async (params = null) => {
        // resetState();
        state.pending = true;
        try {
            const response = await axios.get(`/${resource}`, { params });

            state.data = response.data.data;
            state.links = response.data.links;
            state.meta = response.data.meta;
            state.additional = response.data.additional;
            
            console.log(response);
            return response.data?.meta?.total;
        } catch (error) {
            state.error.statusCode = error.response.status;
            state.error.name = error.message;
            state.error.message = error.response.data.message;
        } finally {
            state.pending = false;
        }
    }

    return { ...toRefs(state), load }
}

export default getCollection