import AnexoCreate from '../views/alunos/anexos/Create';
import AnexoEdit from '../views/alunos/anexos/Edit';

const anexosRoutes = [
    {
        path: '/alunos/:id/anexos/create',
        name: 'Anexo.Create',
        component: AnexoCreate,
        props: true
    },
    // {
    //     path: '/anexos/:id/edit',
    //     name: 'Anexo.Edit',
    //     component: AnexoEdit,
    //     props: true
    // },
]

export default anexosRoutes;