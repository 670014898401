import { render } from "./DateInput.vue?vue&type=template&id=f3a4ba6a"
import script from "./DateInput.vue?vue&type=script&lang=js"
export * from "./DateInput.vue?vue&type=script&lang=js"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QPopupProxy,QDate});
