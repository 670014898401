import axios from 'axios';
import authStore from "@/store/auth";
import router from '@/router';

const interceptor = () => {  
    axios.interceptors.request.use((config) => {
        config.baseURL = process.env.VUE_APP_API_URL;
        config.headers['Authorization'] = `Bearer ${authStore.state.token}`;
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response.status === 401 
            && router.currentRoute.value.name !== 'Login' 
            && error.response.config.url !== '/logout') {
            (async () => {
                await authStore.dispatch('logout');
            })();
        } else if (error.response.status === 404) {
            router.push('/404-not-found');
        }
    
        return Promise.reject(error);
    });
}

export default interceptor