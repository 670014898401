import { reactive, toRefs } from "vue";
import axios from 'axios';

const useModel = (resource) => {

    const initialState = reactive({
        response: null,
        pending: false,
        error: null,
    });

    const state = reactive({ ...initialState });

    const resetState = () => {
        Object.assign(state, initialState);
    }

    const update = async (id, model) => {
        resetState();
        state.pending = true;

        try {
            const response = await axios.put(`/${resource}/${id}`, model);
            console.log(response);
            state.response = response;
            return response;
        } catch (error) {
            console.log(error.message);
            console.log(error.response.data);
            console.log(error.response.data.message);
            state.error = error.response.data
        } finally {
            state.pending = false;
        }
    }

    const destroy = async (id) => {
        resetState();
        state.pending = true;

        try {
            const response = await axios.delete(`/${resource}/${id}`);
            console.log(response);
            state.response = response;
            return response;
        } catch (error) {
            console.log(error.message);
            console.log(error.response.data);
            console.log(error.response.data.message);
            state.error = error.response.data
        } finally {
            state.pending = false;
        }
    }

    return { ...toRefs(state), update, destroy }
}

export default useModel