<template>
  <q-form @submit="handleSubmit" class="q-pa-lg">
    <template v-for="(apoio, index) in form.diagnosticos" :key="index">
      <template v-if="form.diagnosticos[index]">
        <div class="row q-col-gutter-lg q-mb-md">
          <div class="col">


           <q-select filled v-model="form.diagnosticos[index].doenca" :options="cid" label="Diagnóstico" emit-value map-options :error-message="error?.errors?.[`diagnosticos.${index}.doenca`]?.[0]" :error="error?.errors?.[`diagnosticos.${index}.doenca`]?.length > 0"/>                          
           </div>
           <div class="col">
            <q-toggle v-model="form.diagnosticos[index].acompanhamento" label="Acompanhamento" />
          </div>
        </div>

        <div class="row justify-end" v-if="index != 0">
          <q-btn color="red" icon="delete" @click="removerCampos(index)"/>
        </div>

        <q-separator spaced="xl" />
      </template>
    </template>

    <div class="row justify-between">
      <q-btn color="green" label="Adicionar outro diagnóstico" @click="adicionarCampos()"/>
      <q-btn label="Salvar" type="submit" color="primary" :loading="pending"/>
    </div>
  </q-form>
</template>

<script>
  import { ref, reactive } from 'vue'

  import getCollection from '@/composables/getCollection';
  import useCollection from '@/composables/useCollection';
  import useNotify from '@/composables/useNotify';
  import getOptions from '@/composables/getOptions';
  export default {
    props: ['id'],
    setup (props, context) {
      const { data, load: resourceLoad, pending: resourcePending, error: resourceError } = getCollection(`alunos/${props.id}/fichas/diagnosticos`);
      const { pending, error, create } = useCollection(`alunos/${props.id}/fichas/diagnosticos`);
      const { triggerPositive, triggerNegative } = useNotify();
      
      const initialState = {
        doenca: '',
        acompanhamento: false,
      };

      const form = reactive({
        diagnosticos: [
        Object.assign({}, initialState)
        ]
      });

      resourceLoad().then(() => {
        Object.assign(form.diagnosticos, data.value);        
        if(data.value.length !== 0) {
         context.emit('check','diagnosticos');
       }
     });

      const handleSubmit = async () => {
        const response = await create(form);
        if(response) {
          triggerPositive('Salvo com sucesso!');
          context.emit('check','diagnosticos');
        } else {
          triggerNegative('Verifique se os campos estão corretos.');
        }
      }

      
      

      const cidOptions = getOptions('cid');    
      cidOptions.load();
      const adicionarCampos = () => {
        form.diagnosticos.push(Object.assign({}, initialState));
      }

      const removerCampos = (index) => {
        form.diagnosticos.splice(index, 1);
      }

      return {
        form, resourcePending, resourceError,
        pending, error,
        handleSubmit, adicionarCampos, removerCampos,
        cid:cidOptions.data,
        
      }
    }
  }
</script>

<style>

</style>