<template>
  <template v-if="form && Object.keys(form).length >= 1">
    
    <div class="row justify-center">
      <div class="col col-lg-6">
        <q-list>
          <q-item>
            <q-item-section>Data de abertura: {{ $root.dmy(form.abertura) }}</q-item-section>
          </q-item>

          <q-item>
            <q-item-section>
              <q-item-label>Encaminhamento: {{ form.encaminhamento }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>

    <!--   <div class="col col-lg-6">
        <q-list>
          <q-item>
            <q-item-section>Motivo da procura: {{ form.motivo_procura }}</q-item-section>
          </q-item>
        </q-list>
      </div> -->
    </div>

    <q-separator />

    <div class="row justify-center q-my-md">
      <q-btn color="primary" label="Ficha completa" icon="edit" :to="{ name: 'Ficha.Edit', params: { id: this.$props.id } }"/>
    </div>
  </template>

  <div class="row justify-center q-my-md" v-if="form?.length === 0">
    <q-btn color="primary" label="Criar ficha" icon="add" :to="{ name: 'Ficha.Edit', params: { id: this.$props.id } }"/>
  </div>

  <!-- <ListSkeleton v-if="resourcePending" /> -->
  <div class="q-pa-md flex flex-center" v-if="resourcePending">
    <q-circular-progress indeterminate size="50px" color="lime" class="q-ma-md" />
  </div>
</template>

<script>
import getModel from '@/composables/getModel';
import ListSkeleton from '@/components/layout/skeletons/ListSkeleton';

export default {
  props: ['id'],
  components: { ListSkeleton },
  setup(props, context) {
    const { data: form, load: resourceLoad, pending: resourcePending, error: resourceError } = getModel(`alunos/${props.id}/fichas`);

    resourceLoad();

    return { 
      form, resourcePending, resourceError
    }
  }
}
</script>

<style>

</style>