import { reactive, toRefs } from "vue";
import axios from 'axios';

const uploadFile = () => {
    const initialState = reactive({
        response: null,
        pending: false,
        error: null,
    });

    const state = reactive({ ...initialState });

    const resetState = () => {
        Object.assign(state, initialState);
    }

    const upload = async (endpoint, content) => {
        resetState();
        state.pending = true;
        
        try {
            const formData = new FormData();
            formData.append('foto', content);

            const response = await axios.post(`/${endpoint}`, formData, { 
                // headers: {
                //     'Content-Type': 'multipart/form-data',
                //     'Accept': 'application/json',
                // },
                params: { 
                    '_method': 'PATCH' 
                }
            });

            console.log(response);
            state.response = response;
            return response;
        } catch (error) {
            console.log(error.message);
            console.log(error.response.data);
            console.log(error.response.data.message);

            state.error = error.response.data
        } finally {
            state.pending = false;
        }
    }

    return { ...toRefs(state), upload }
}

export default uploadFile