import axios from 'axios'
import { reactive, toRefs } from "vue";

const getModel = (endpoint, fetchNow = false) => {

    const initialState = reactive({
        data: null,
        pending: false,
        error: {
            statusCode: null,
            name: null,
            message: null
        }
    });

    const state = reactive({ ...initialState });

    const load = async () => {
        state.pending = true;

        try {
            const response = await axios.get(`/${endpoint}`);
            state.data = response.data;

            console.log(response);
        } catch (error) {         
            state.error.statusCode = error.response.status;
            state.error.name = error.message;
            state.error.message = error.response.data.message;
        } finally {
            state.pending = false;
        }
    }

    if (fetchNow === true) {
        load();
    }

    return { ...toRefs(state), load }
}

export default getModel