import { render } from "./ListSkeleton.vue?vue&type=template&id=433e515d"
import script from "./ListSkeleton.vue?vue&type=script&lang=js"
export * from "./ListSkeleton.vue?vue&type=script&lang=js"
script.render = render

export default script
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSkeleton,QSeparator});
