<template>
  <q-form @submit="handleSubmit" class="q-pa-lg">
    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col-12 col-sm-4">
        <q-toggle v-model="form.servico" label="Serviços" />
      </div>
      <div class="col-12 col-sm-4">
        <q-toggle v-model="form.encaminhamento_juridico" label="Encaminhamento jurídico" />
      </div>
      <div class="col-12 col-sm-4">
        <q-toggle v-model="form.encaminhamento_terapeutico" label="Encaminhamento terapêutico" />
      </div>
    </div>

    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col-12 col-sm-4">
        <q-toggle v-model="form.encaminhamento_psicologico" label="Encaminhamento psicológico" />
      </div>
      <div class="col-12 col-sm-4">
        <q-toggle v-model="form.encaminhamento_medico" label="Encaminhamento para médicos e exames" />
      </div>
      <div class="col-12 col-sm-4">
        <q-toggle v-model="form.encaminhamento_socioassistencial" label="Encaminhamento para serviços socioassistenciais" />
      </div>
    </div>

    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col-12 col-sm-4">
        <q-toggle v-model="form.orientacao" label="Orientações" />
      </div>
      <div class="col-12 col-sm-4">
        <q-toggle v-model="form.alimentar" label="Necessidade alimentar" />
      </div>
      <div class="col-12 col-sm-4">
        <q-toggle v-model="form.transporte" label="Transporte" />
      </div>
    </div>

    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col-12 col-sm-4">
        <q-toggle v-model="form.inss" label="INSS" />
      </div>
      <div class="col-12 col-sm-4">
        <q-toggle v-model="form.fralda" label="Fraldas" />
      </div>
      <div class="col-12 col-sm-4">
        <q-input v-model="form.outro" label="Outro" :error-message="error?.errors?.outro?.[0]" :error="error?.errors?.outro?.length > 0"/>
      </div>
    </div>

    <q-separator spaced="xl" />

    <div class="row justify-end">
      <q-btn label="Salvar" type="submit" color="primary" :loading="pending"/>
    </div>
  </q-form>
</template>

<script>
import { ref, reactive } from 'vue'

import getModel from '@/composables/getModel';
import useCollection from '@/composables/useCollection';
import useNotify from '@/composables/useNotify';

export default {
  props: ['id'],
  setup (props, context) {
    const { data, load: resourceLoad, pending: resourcePending, error: resourceError } = getModel(`alunos/${props.id}/fichas/necessidades`);
    const { pending, error, create } = useCollection(`alunos/${props.id}/fichas/necessidades`);
    const { triggerPositive, triggerNegative } = useNotify();

    const form = reactive({
      servico: false,
      encaminhamento_juridico: false,
      encaminhamento_terapeutico: false,
      encaminhamento_psicologico: false,
      encaminhamento_medico: false,
      encaminhamento_socioassistencial: false,
      orientacao: false,
      alimentar: false,
      transporte: false,
      inss: false,
      fralda: false,
      outro: '',
    });

    resourceLoad().then(() => {
      Object.assign(form, data.value);
       if(data.value.length !== 0) {
         context.emit('check','necessidades');
       }
    });

    const handleSubmit = async () => {
      const response = await create(form);
      if(response) {
        triggerPositive('Salvo com sucesso!');
          context.emit('check','necessidades');
      } else {
        triggerNegative('Verifique se os campos estão corretos.');
      }
    }

    return {
      form, resourcePending, resourceError,
      pending, error,
      handleSubmit
    }
  }
}
</script>

<style>

</style>