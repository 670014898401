<template>
  <div class="q-pa-md q-my-lg">
    <div class="row justify-center">
      <div class="col col-lg-8">
        <q-card>
          <q-card-section>
            <q-form @submit="handleSubmit" class="q-pa-lg">
              <div class="row q-col-gutter-lg q-mb-md">
                <div class="col">
                  <q-input filled v-model="form.nome" label="Nome" :error-message="error?.errors?.nome?.[0]" :error="error?.errors?.nome?.length > 0"/>
                  </div>

                  <div class="col">
                    <date-input :value="form.data_nascimento" :set="(v) => { form.data_nascimento = v }" label="Data de nascimento" />

                    </div>
                     <div class="col">
                  <q-input filled v-model="form.cartao_sus" label="Cartão SUS" mask="### #### #### ####" :error-message="error?.errors?.cartao_sus?.[0]" :error="error?.errors?.cartao_sus?.length > 0"/>
                  </div>
                  </div>

                  <div class="row q-col-gutter-lg q-mb-md">
                    <div class="col">
                      <q-input filled v-model="form.rg" label="RG" :error-message="error?.errors?.rg?.[0]" :error="error?.errors?.rg?.length > 0"/>
                      </div>
                      <div class="col">
                        <q-input filled v-model.lazy="form.cpf" @change="cpfValidation('cpf')" label="CPF" :error-message="error?.errors?.cpf?.[0]" :error="error?.errors?.cpf?.length > 0" mask="###.###.###-##" unmasked-value/>
                        </div>
                        <div class="col">
                          <q-select filled v-model="form.cid" :options="cid" label="Diagnóstico" emit-value map-options :error-message="error?.errors?.cid?.[0]" :error="error?.errors?.cid?.length > 0"/>
                          </div>
                        </div>

                        <q-separator spaced="xl" />

                        <div class="row q-col-gutter-lg q-mb-md">
                          <div class="col">
                            <q-input filled v-model="form.nome_mae" label="Nome da mãe" :error-message="error?.errors?.nome_mae?.[0]" :error="error?.errors?.nome_mae?.length > 0"/>
                            </div>
                            <div class="col">
                              <q-input filled v-model="form.rg_mae" label="RG da mãe" :error-message="error?.errors?.rg_mae?.[0]" :error="error?.errors?.rg_mae?.length > 0"/>
                              </div>
                              <div class="col">
                                <q-input filled v-model.lazy="form.cpf_mae" @change="cpfValidation('cpf_mae')" label="CPF da mãe" :error-message="error?.errors?.cpf_mae?.[0]" :error="error?.errors?.cpf_mae?.length > 0" mask="###.###.###-##" unmasked-value/>
                                </div>

                              </div>

                              <div class="row q-col-gutter-lg q-mb-md">
                                <div class="col">
                                  <q-input filled v-model="form.nome_pai" label="Nome do pai" :error-message="error?.errors?.nome_pai?.[0]" :error="error?.errors?.nome_pai?.length > 0"/>
                                  </div>
                                  <div class="col">
                                    <q-input filled v-model="form.rg_pai" label="RG do pai" :error-message="error?.errors?.rg_pai?.[0]" :error="error?.errors?.rg_pai?.length > 0"/>
                                    </div>
                                    <div class="col">
                                      <q-input filled v-model.lazy="form.cpf_pai" @change="cpfValidation('cpf_pai')" label="CPF do pai" :error-message="error?.errors?.cpf_pai?.[0]" :error="error?.errors?.cpf_pai?.length > 0" mask="###.###.###-##" unmasked-value/>
                                      </div>

                                    </div>

                                    <q-separator spaced="xl" />
                                    <div class="row q-col-gutter-lg q-mb-md">
                                      <div class="col">
                                        <q-input filled v-model="form.nome_responsavel" label="Nome do responsável" :error-message="error?.errors?.nome_responsavel?.[0]" :error="error?.errors?.nome_responsavel?.length > 0"/>
                                        </div>
                                        <div class="col">
                                          <q-input filled v-model="form.email" label="Email" :error-message="error?.errors?.email?.[0]" :error="error?.errors?.email?.length > 0"/>
                                          </div>

                                        </div>
                                        <div class="row q-col-gutter-lg q-mb-md">
                                         <div class="col">
                                          <q-input filled v-model="form.rg_responsavel" label="RG do responsável" :error-message="error?.errors?.rg_responsavel?.[0]" :error="error?.errors?.rg_responsavel?.length > 0"/>
                                          </div>
                                          <div class="col">
                                            <q-input filled v-model.lazy="form.cpf_responsavel" @change="cpfValidation('cpf_responsavel')" label="CPF do responsável" :error-message="error?.errors?.cpf_responsavel?.[0]" :error="error?.errors?.cpf_responsavel?.length > 0" mask="###.###.###-##" unmasked-value/>
                                            </div>
                                          </div>

                                          <div class="row q-col-gutter-lg q-mb-md">
                                            <div class="col">
                                              <q-input filled v-model="form.telefone" label="Telefone 1" :error-message="error?.errors?.telefone?.[0]" :error="error?.errors?.telefone?.length > 0" mask="(##) #########" unmasked-value/>
                                              </div>
                                              <div class="col">
                                               <q-input filled v-model="form.telefone2" label="Telefone 2" :error-message="error?.errors?.telefone2?.[0]" :error="error?.errors?.telefone2?.length > 0" mask="(##) #########" unmasked-value/>
                                               </div>
                                               <div class="col">
                                                <q-input filled v-model="form.telefone3" label="Telefone 3" :error-message="error?.errors?.telefone3?.[0]" :error="error?.errors?.telefone3?.length > 0" mask="(##) #########" unmasked-value/>
                                                </div>
                                                <div class="col">
                                                  <q-input filled v-model="form.telefone4" label="Telefone 4" :error-message="error?.errors?.telefone4?.[0]" :error="error?.errors?.telefone4?.length > 0" mask="(##) #########" unmasked-value/>
                                                  </div>

                                                </div>

                                                <q-separator spaced="xl"/>

                                                <div class="row q-col-gutter-lg q-mb-md">
                                                  <div class="col">
                                                    <q-input filled v-model.lazy="form.address.cep" @change="buscaCep" label="CEP" :error-message="error?.errors?.['address.cep']?.[0]" :error="error?.errors?.['address.cep']?.length > 0" mask="#####-###" unmasked-value/>
                                                    </div>
                                                    <div class="col">
                                                      <q-input filled v-model="form.address.estado" label="Estado" :error-message="error?.errors?.['address.estado']?.[0]" :error="error?.errors?.['address.estado']?.length > 0"/>
                                                      </div>
                                                      <div class="col">
                                                        <q-input filled v-model="form.address.cidade" label="Cidade" :error-message="error?.errors?.['address.cidade']?.[0]" :error="error?.errors?.['address.cidade']?.length > 0"/>
                                                        </div>
                                                      </div>

                                                      <div class="row q-col-gutter-lg">
                                                        <div class="col">
                                                          <q-input filled v-model="form.address.bairro" label="Bairro" :error-message="error?.errors?.['address.bairro']?.[0]" :error="error?.errors?.['address.bairro']?.length > 0"/>
                                                          </div>
                                                          <div class="col">
                                                            <q-input filled v-model="form.address.rua" label="Rua" :error-message="error?.errors?.['address.rua']?.[0]" :error="error?.errors?.['address.rua']?.length > 0"/>
                                                            </div>
                                                            <div class="col">
                                                              <q-input filled v-model="form.address.numero" label="Número" :error-message="error?.errors?.['address.numero']?.[0]" :error="error?.errors?.['address.numero']?.length > 0"/>
                                                              </div>
                                                              <div class="col">
                                                                <q-input filled v-model="form.address.complemento" label="Complemento" :error-message="error?.errors?.['address.complemento']?.[0]" :error="error?.errors?.['address.complemento']?.length > 0"/>
                                                                </div>
                                                              </div>

                                                              <q-separator spaced="xl" />

                                                              <div class="row q-col-gutter-lg q-mb-md">
                                                                <div class="col">
                                                                  <q-select filled v-model="form.tipo" :options="tiposOptions" label="Tipo" emit-value map-options :error-message="error?.errors?.tipo?.[0]" :error="error?.errors?.tipo?.length > 0"/>
                                                                  </div>
                                                                  <div class="col">
                                                                    <q-select filled v-model="form.status" :options="statusOptions" label="Status" emit-value map-options :error-message="error?.errors?.status?.[0]" :error="error?.errors?.status?.length > 0"/>
                                                                    </div>
                                                                  </div>

                                                                  <q-separator spaced="xl" />

                                                                  <div class="row justify-end">
                                                                    <q-btn label="Salvar" type="submit" color="primary" :loading="pending"/>
                                                                  </div>
                                                                </q-form>
                                                              </q-card-section>
                                                            </q-card>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </template>

                                                    <script>
                                                      import { reactive } from "vue";

                                                      import useCollection from '@/composables/useCollection';
                                                      import getOptions from '@/composables/getOptions';
                                                      import endereco from '@/composables/endereco';
                                                      import helper from '@/helper';
                                      //import cid from '@/composables/pages/alunos/cid';
                                      import useNotify from '@/composables/useNotify';
                                      import DateInput from '@/components/ui/DateInput';

                                      const tiposOptions = [
                                      { label: 'Bolsista', value: 'bolsista' },
                                      { label: 'Prefeitura', value: 'prefeitura' },
                                      { label: 'Particular', value: 'particular' },
                                      { label: 'Convênio Médico', value: 'convênio médico' }
                                      ];

                                      const statusOptions = [
                                      { label: 'Ativo', value: 'ativo' },
                                      { label: 'Fila', value: 'fila' },
                                      { label: 'Alta', value: 'alta' }
                                      ];

                                      export default {
                                        components:{
                                          DateInput
                                        },
                                        methods:{

                                          buscaCep(){
                                            endereco.busca(this.form.address.cep).then(res => {
                                              if(!res.data.ok){
                                               this.form.address.cep = null;
                                               useNotify().triggerNegative('CEP inválido.');
                                               return false;
                                             }
                                             this.form.address.rua = res.data.address
                                             this.form.address.cidade =res.data.city
                                             this.form.address.bairro =res.data.district
                                             this.form.address.estado =res.data.state
                                           } );
                                          },
                                          cpfValidation(cpf){
                                            if(helper.isValidCPF(this.form[cpf])){
                                              return true;
                                            }else{
                                              useNotify().triggerNegative('CPF inválido.');
                                              this.form[cpf] = '';
                                            }
                                          }
                                        },
                                        setup(props, context) {
                                          const { pending, error, create } = useCollection('alunos');
                                          const cidOptions = getOptions('cid');    
                                          cidOptions.load();
                                          


                                          const { triggerPositive, triggerNegative } = useNotify();                                          
                                          const initialState = reactive({
                                            nome: '',
                                            sobrenome: '',
                                            data_nascimento: '',
                                            rg: '',
                                            cpf: '',
                                            diagnostico: '',
                                            nome_responsavel: '',
                                            sobrenome_responsavel: '',
                                            telefone: '',
                                            telefone2: '',
                                            telefone3: '',
                                            telefone4: '',
                                            email: '',
                                            address: {
                                              cep: '',
                                              estado: '',
                                              cidade: '',
                                              bairro: '',
                                              rua: '',
                                              numero: '',
                                              complemento: '',
                                            },
                                            tipo: '',
                                            cid:'',
                                            status: ''
                                          });                                          
                                          const form = reactive({ ...initialState });

                                          const resetForm = () => {
                                            Object.assign(form, initialState);
                                          }

                                          const handleSubmit = async () => {
                                            const response = await create(form);
                                            if(response) {
                                              resetForm();
                                              triggerPositive('Criado com sucesso!');
                                            } else {
                                              triggerNegative('Verifique se os campos estão corretos.');
                                            }
                                          };

                                          return { 
                                            form, 
                                            pending, error,
                                            tiposOptions, statusOptions,
                                            handleSubmit,
                                            cid:cidOptions.data
                                          }
                                        }
                                      }
                                    </script>

                                    <style>

                                  </style>