import ModeloIndex from '../views/modelos/Index';
import ModeloCreate from '../views/modelos/Create';

const modelosRoutes = [
    {
        path: '/modelos',
        name: 'Modelo.Index',
        component: ModeloIndex
    },
    {
        path: '/modelos/create',
        name: 'Modelo.Create',
        component: ModeloCreate
    },
]

export default modelosRoutes;