<template>
  <div class="q-pa-md">
    <div class="row justify-center">
      <div class="col col-lg-6">
        <q-card>
          <q-tabs v-model="tab" class="text-grey" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
            <q-tab name="info" label="Informações básicas" />
            <q-tab name="ficha" label="Ficha" />
            <q-tab name="anexo" label="Anexos" />
          </q-tabs>

          <q-separator />

          <q-tab-panels v-model="tab" animated keep-alive>
            <q-tab-panel name="info">
              <Detalhes :id="alunoId" />
            </q-tab-panel>

            <q-tab-panel name="ficha">
              <Ficha :id="alunoId" />
            </q-tab-panel>

            <q-tab-panel name="anexo">
              <Anexos :id="alunoId" />
            </q-tab-panel>

          </q-tab-panels>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import Detalhes from '@/components/pages/alunos/show/Detalhes';
import Ficha from '@/components/pages/alunos/show/Ficha';
import Anexos from '@/components/pages/alunos/show/Anexos';

export default {
  props: ['id'],
  components: { Detalhes, Ficha, Anexos },
  setup(props, context) {
    return {
      tab: ref('info'),
      alunoId: props.id
    }
  }
}
</script>

<style>

</style>