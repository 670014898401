<template>

  <div class="q-pa-md q-my-lg">
    <div class="row justify-center" @click="print" v-if="
    checksState.redes &&
    checksState.diagnosticos &&
    checksState.servicos &&
    checksState.beneficios &&
    checksState.necessidades
    
    ">
    <div class="q-pa-md q-gutter-sm">
      <q-btn  color="primary" label="Imprimir" icon-right="print"></q-btn>
    </div>

  </div>
  <div class="row justify-center">
    <div class="col col-lg-6">
      <q-card>
        <q-card-section>
          <q-expansion-item expand-separator expand-icon-toggle icon="assignment" :label="fichaState ? 'Ficha - Concluída' : 'Ficha'"  caption="Primeiro passo" group="ficha">
           <template v-slot:header>
            <q-item-section avatar>            
              <i class="notranslate material-icons q-icon" aria-hidden="true" role="presentation">assignment</i>
            </q-item-section>

            <q-item-section>
             Ficha 
           </q-item-section>

           <q-item-section side>
            <div class="row items-center">
              <q-icon v-if="fichaState" name="check" color="green" size="24px" />

            </div>
          </q-item-section>
        </template>

        <Ficha :id="alunoId" @created="fichaCriada"/>
      </q-expansion-item>
    </q-card-section>

    <q-card-section>
      <q-expansion-item expand-separator expand-icon-toggle icon="house" :label="apoios.length ? 'Rede Familiar e de Apoio  - Concluída' : 'Rede Familiar e de Apoio'" group="ficha" :disable="!fichaState">
       <template v-slot:header>
        <q-item-section avatar>            
          <i class="notranslate material-icons q-icon" aria-hidden="true" role="presentation">house</i>
        </q-item-section>
        <q-item-section>
          Rede Familiar e de Apoio
        </q-item-section>
        <q-item-section side>
          <div class="row items-center">
            <q-icon v-if="checksState.redes" name="check" color="green" size="24px" />
          </div>
        </q-item-section>
      </template>
      <Apoio :id="alunoId" v-if="fichaState" @check="checkChanger"/>
    </q-expansion-item>
  </q-card-section>

  <q-card-section>
    <q-expansion-item expand-separator expand-icon-toggle icon="request_quote" label="Programas sociais e benefícios" group="ficha" :disable="!fichaState">
      <template v-slot:header>
        <q-item-section avatar>            
          <i class="notranslate material-icons q-icon" aria-hidden="true" role="presentation">request_quote</i>
        </q-item-section>
        <q-item-section>
         Programas sociais e benefícios
       </q-item-section>
       <q-item-section side>
        <div class="row items-center">
          <q-icon v-if="checksState.beneficios" name="check" color="green" size="24px" />
        </div>
      </q-item-section>
    </template>
    <Beneficio :id="alunoId" v-if="fichaState" @check="checkChanger"/>
  </q-expansion-item>
</q-card-section>

<q-card-section>
  <q-expansion-item expand-separator expand-icon-toggle icon="perm_identity" label="Serviços socioassistenciais" group="ficha" :disable="!fichaState">
    <template v-slot:header>
      <q-item-section avatar>            
        <i class="notranslate material-icons q-icon" aria-hidden="true" role="presentation">perm_identity</i>
      </q-item-section>
      <q-item-section>
       Serviços socioassistenciais
     </q-item-section>
     <q-item-section side>
      <div class="row items-center">
        <q-icon v-if="checksState.servicos" name="check" color="green" size="24px" />
      </div>
    </q-item-section>
  </template>
  <Assistencia :id="alunoId" v-if="fichaState" @check="checkChanger"/>
</q-expansion-item>
</q-card-section>

<q-card-section>
  <q-expansion-item expand-separator expand-icon-toggle icon="health_and_safety" label="Condições de saúde" group="ficha" :disable="!fichaState">
    <template v-slot:header>
      <q-item-section avatar>            
        <i class="notranslate material-icons q-icon" aria-hidden="true" role="presentation">health_and_safety</i>
      </q-item-section>
      <q-item-section>
       Condições de saúde 
     </q-item-section>
     <q-item-section side>
      <div class="row items-center">
        <q-icon v-if="checksState.diagnosticos" name="check" color="green" size="24px" />
      </div>
    </q-item-section>
  </template>
  <Diagnostico :id="alunoId" v-if="fichaState" @check="checkChanger"  ref="diagnostico"/>
</q-expansion-item>
</q-card-section>

<q-card-section>
  <q-expansion-item expand-separator expand-icon-toggle icon="accessible" label="Necessidades e intervenções" group="ficha" :disable="!fichaState">
   <template v-slot:header>
    <q-item-section avatar>            
      <i class="notranslate material-icons q-icon" aria-hidden="true" role="presentation">accessible</i>
    </q-item-section>
    <q-item-section>
     Necessidades e intervenções
   </q-item-section>
   <q-item-section side>
    <div class="row items-center">
      <q-icon v-if="checksState.necessidades" name="check" color="green" size="24px" />
    </div>
  </q-item-section>
</template>
<Necessidade :id="alunoId" v-if="fichaState" @check="checkChanger"/>
</q-expansion-item>
</q-card-section>
</q-card>

<q-inner-loading :showing="resourcePending || printing">
  <q-spinner color="primary" size="5em"/>
</q-inner-loading>
</div>
</div>
</div>
</template>

<script>
  import { computed, ref ,reactive} from 'vue';

  import getModel from '@/composables/getModel';
  import Ficha from '@/components/pages/alunos/fichas/Ficha';
  import Apoio from '@/components/pages/alunos/fichas/Apoio';
  import Diagnostico from '@/components/pages/alunos/fichas/Diagnostico';
  import Necessidade from '@/components/pages/alunos/fichas/Necessidade';
  import Beneficio from '@/components/pages/alunos/fichas/Beneficio';
  import Assistencia from '@/components/pages/alunos/fichas/Assistencia';

  import axios from 'axios';
import { useQuasar } from 'quasar'
  export default {
    props: ['id'],
    data(){
      return {
        apoios: [],
        printing:false
      }
    }, 
    created(){
      //this.getApoios();

    },
    methods: {
      print(){
        this.printing=true
        axios.post('/pdf/'+this.id,{}, {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" '
            },
            responseType: "blob"
          }).then((response) => {

            const blob = new Blob([response.data], { type: 'application/pdf'})          
            const objectUrl = window.URL.createObjectURL(blob)

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";          
            a.href = objectUrl;
            a.target='new';
           // a.download = this.modal.exportar.mode =='pdf'?'relatório_chamados.pdf':'relátorio_chamados.xlsx';
            a.click();
            window.URL.revokeObjectURL(objectUrl);
             this.printing=false;
          })
          .catch((error) => {                    
            console.log(error);
            this.printing=false;
            if (error.response.data.type == 'application/json') {
             this.$toast.clear();
             this.$toast.error('Nenhum chamado encontrado com esses filtros',{position:'top'});
             this.$root.loading = false;
           } 
         });
        }
      },
      components: { Ficha, Apoio, Diagnostico, Necessidade, Beneficio, Assistencia },
      setup (props, context) {
        const { data, load: resourceLoad, pending: resourcePending, error: resourceError } = getModel(`alunos/${props.id}/fichas`);
        const fichaState = ref(false);
        const checksState = reactive({
          diagnosticos:false,
          beneficios:false,
          redes:false,        
          necessidades:false,
          beneficios:false,
          servicos:false,
        });
      // const fichaState = ref(false);
      // const fichaState = ref(false);
      // const fichaState = ref(false);
      // const fichaState = ref(false);
      // const fichaState = ref(false);
      // const fichaState = ref(false);
        const $q = useQuasar();
      resourceLoad().then(() => {
        if(data.value.length !== 0) {
          fichaCriada();
        }
      });

      const fichaCriada = () => {
        fichaState.value = true;
      }

      const checkChanger = (component) => {
        //console.log('check',component)
        checksState[component] = true;
        
      }
      return {
        alunoId: props.id,
        resourcePending,
        fichaState, fichaCriada,checkChanger,checksState,$q
      }
    }
  }
</script>

<style>

</style>