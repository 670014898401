import { render } from "./Edit.vue?vue&type=template&id=5b61baa6"
import script from "./Edit.vue?vue&type=script&lang=js"
export * from "./Edit.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QCard,QCardSection,QExpansionItem,QItemSection,QIcon,QInnerLoading,QSpinner});
