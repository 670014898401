<template>
  <q-page class="row justify-center items-center">
    <div class="col col-sm-6 col-md-3">
      <q-card class="my-card">
        <q-card-section>
          <q-form @submit="handleSubmit" class="q-gutter-md">
            <q-input filled v-model="credentials.email" label="Email *" :rules="[ val => val && val.length > 0 || 'Campo obrigatório']"/>
            <q-input filled v-model="credentials.password" label="Senha *" :type="isPwd ? 'password' : 'text'" :rules="[ val => val && val.length > 0 || 'Campo obrigatório']">
              <template v-slot:append>
                <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd"/>
              </template>
            </q-input>

            <div>
              <q-btn label="Enviar" type="submit" color="primary" :loading="pending"/>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import authStore from "@/store/auth";
import useNotify from '@/composables/useNotify';

export default {
  setup() {
    const { triggerPositive, triggerNegative } = useNotify();
    const router = useRouter();

    const pending = computed(() => authStore.getters.pending);
    const error = computed(() => authStore.getters.error);
    const isPwd = ref(true);

    const credentials = reactive({
      email: '',
      password: '',
    });

    const handleSubmit = async () => {
      const response = await authStore.dispatch('login', credentials);

      if (!response) {
        triggerNegative(error);
      }
    };

    return {
      isPwd, 
      credentials, 
      handleSubmit, 
      pending, error 
    }
  }
}
</script>

<style>

</style>