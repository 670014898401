<template>
  <q-form @submit="handleSubmit" class="q-pa-lg">
    <div class="text-h5 text-center q-mb-md">Procedência</div>

    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col">

        <date-input  :value="form.abertura" readonly :set="(v) => { form.abertura = v }" label="Data de abertura" />


        </div>

        <div class="col">

         <q-select filled v-model="form.encaminhamento" :options="encaminhamentosOptions" label="Encaminhamento" emit-value map-options :error-message="error?.errors?.encaminhamento?.[0]" :error="error?.errors?.encaminhamento?.length > 0"/>
         </div>
       </div>

       <q-separator spaced="xl" />

       <div class="text-h5 text-center q-mb-md">Condições de moradia</div>

       <div class="row q-col-gutter-lg q-mb-md">
        <div class="col">          
         <q-select filled v-model="form.condicao_moradia" :options="moradiaOptions" label="Condição de moradia" emit-value map-options :error-message="error?.errors?.condicao_moradia?.[0]" :error="error?.errors?.condicao_moradia?.length > 0"/>
         </div>
         <div class="col">          
           <q-select filled v-model="form.tipo_transporte" :options="transporteOptions" label="Meio de transporte" emit-value map-options :error-message="error?.errors?.tipo_transporte?.[0]" :error="error?.errors?.tipo_transporte?.length > 0"/>
           </div>
           <div class="col">
            <q-toggle v-model="form.transporte_equoterapia" :label="`Interesse no transporte Equoterapia?`" />
          </div>
        </div>

        <q-separator spaced="xl" />

        <div class="text-h5 text-center q-mb-md">Condições de saúde</div>

        <div class="row q-col-gutter-lg q-mb-md">
          <div class="col">
            <q-toggle  v-model="form.usf_ubs" :label="'USF ou UBS utilizada'" />
          </div>

          <div class="col">
            <q-toggle filled v-model="form.plano_saude" label="Plano de saúde particular" />
          </div>
        </div>

        <q-separator spaced="xl" />

        <div class="text-h5 text-center q-mb-md">Educação</div>

        <div class="row q-col-gutter-lg q-mb-md">
         <div class="col">
          <q-toggle  v-model="form.aluno_estuda" :label="'Praticante estuda?'" />
        </div>
        <div class="col">          
          <q-select filled v-model="form.tipo_instituicao_educacional" :disable="!form.aluno_estuda" :options="tipoEnsinoOptions" label="Tipo de ensino" emit-value map-options :error-message="error?.errors?.tipo_instituicao_educacional?.[0]" :error="error?.errors?.tipo_instituicao_educacional?.length > 0"/>
          </div>



        </div>
        <div class="row q-col-gutter-lg q-mb-md">
         <div class="col">
          <q-input filled v-model="form.instituicao_educacional" :disable="!form.aluno_estuda" label="Nome do local de ensino" :error-message="error?.errors?.instituicao_educacional?.[0]" :error="error?.errors?.instituicao_educacional?.length > 0"/>
          </div>
          <div class="col">          
            <q-select filled v-model="form.nivel_educacional" :disable="!form.aluno_estuda" :options="nivelOptions" label="Nível de ensino" emit-value map-options :error-message="error?.errors?.nivel_educacional?.[0]" :error="error?.errors?.nivel_educacional?.length > 0"/>
            </div>
            <div class="col">         
             <q-select filled v-model="form.ano_educacional" :disable="!form.aluno_estuda" :options="anosLetivos" label="Ano educacional" emit-value map-options :error-message="error?.errors?.ano_educacional?.[0]" :error="error?.errors?.ano_educacional?.length > 0"/>
             </div>
           </div>
           <div class="row q-col-gutter-lg q-mb-md">
             <div class="col">
              <q-toggle v-model="form.atendimento_aee" label="Atendido por profissional AEE" />
            </div>
            <div class="col">
              <q-select filled  multiple :options="atividadesTerapeuticasOptions" v-model="form.atividade_complementar" emit-value map-options  label="Atividades terapêuticas complementares" :error-message="error?.errors?.atividade_complementar?.[0]" :error="error?.errors?.atividade_complementar?.length > 0"/>
              </div>
              <div class="col">
                <q-input filled v-model="form.atividade_complementar_local" :options="anosLetivos" label="Local atividade complementar"  :error-message="error?.errors?.atividade_complementar_local?.[0]" :error="error?.errors?.atividade_complementar_local?.length > 0"/>
                </div>
              </div>

              <q-separator spaced="xl" />

              <div class="text-h5 text-center q-mb-md">Situação de trabalho</div>

              <div class="row q-col-gutter-lg q-mb-md">
                <div class="col">
                  <q-input filled v-model="form.emprego_mae" label="Atividade da mãe" :error-message="error?.errors?.emprego_mae?.[0]" :error="error?.errors?.emprego_mae?.length > 0"/>
                  </div>
                  <div class="col">
                    <q-input filled v-model="form.emprego_pai" label="Atividade do pai" :error-message="error?.errors?.emprego_pai?.[0]" :error="error?.errors?.emprego_pai?.length > 0"/>
                    </div>


                  </div>

                  <div class="row q-col-gutter-lg q-mb-md">
                    <div class="col">

                      <q-field
                      filled
                      v-model="form.renda_mae"
                      label="Renda da mãe"

                      >
                      <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <money class="q-field__input text-right" v-model="form.renda_mae" v-bind="money"  />
                      </template>
                    </q-field>


                  </div>
                  <div class="col">

                   <q-field
                   filled
                   v-model="form.renda_pai"
                   label="Renda do pai"

                   >
                   <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                    <money class="q-field__input text-right" v-model="form.renda_pai" v-bind="money"  />
                  </template>
                </q-field>

              </div>
              <div class="col">

               <q-field
               filled
               v-model="form.outra_renda"
               label="Outra renda"

               >
               <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                <money class="q-field__input text-right" v-model="form.outra_renda" v-bind="money"  />
              </template>
            </q-field>

          </div>
          <div class="col">
            <q-select filled v-model="form.renda_familiar" emit-value map-options  :options="rendaFamiliarOptions" readonly label="Renda familiar" :error-message="error?.errors?.renda_familiar?.[0]" :error="error?.errors?.renda_familiar?.length > 0"/>
            </div>
          </div>

          <q-separator spaced="xl" />


          <div class="row justify-end">
            <q-btn label="Salvar" type="submit" color="primary" :loading="pending"/>
          </div>
        </q-form>
      </template>

      <script>
        import { ref, reactive } from 'vue'

        import getModel from '@/composables/getModel';
        import useCollection from '@/composables/useCollection';
        import useNotify from '@/composables/useNotify';
        import DateInput from '@/components/ui/DateInput';
        import {Money3Component} from 'v-money3'
        const encaminhamentosOptions = [
        { label: 'Nenhum', value: 'Nenhum' },

        { label: '30 de julho', value: '30 de julho' },
        { label: 'Anália Franco', value: 'Anália Franco' },
        { label: 'APAE', value: 'APAE' },
        { label: 'APAE A', value: 'APAE A' },
        { label: 'CAPS', value: 'CAPS' },
        { label: 'Casa do Autista', value: 'Casa do Autista' },
        { label: 'Casa da Esperança', value: 'Casa da Esperança' }, 
        { label: 'CREAs', value: 'CREAs' },   
        { label: 'Educação', value: 'Educação' },          
        { label: 'Lar das Moças Cegas', value: 'Lar das Moças Cegas' },
        { label: 'Mensageiros da Luz', value: 'Mensageiros da Luz' },
        { label: 'Nurex', value: 'Nurex' },
        { label: 'Napne', value: 'Napne' },
        { label: 'Outros', value: 'Outros' },
        { label: 'Saúde', value: 'Saúde' },
        { label: 'Vó Benedita', value: 'Vó Benedita' },

        ];
        const moradiaOptions = [
        { label: 'Alugada', value: 'Alugada' },
        { label: 'Cedida', value: 'Cedida' },
        { label: 'Financiada', value: 'Financiada' },
        { label: 'Própria', value: 'Própria' }

        ];
        const transporteOptions = [
        { label: 'Particular', value: 'Particular' },
        { label: 'Público', value: 'Público' },          
        { label: 'Não utiliza', value: 'Não utiliza' }        
        ];

        const tipoEnsinoOptions = [
        { label: '', value: '' },
        { label: 'Escola pública', value: 'Escola pública' },
        { label: 'Escola particular', value: 'Escola particular' },          
        { label: 'Instituição', value: 'Instituição' }        
        ];


        const nivelOptions = [
        { label: '', value: '' },
        { label: 'Educação infantil', value: 'Educação infantil' },
        { label: 'Ensino fundamental', value: 'Ensino fundamental' },          
        { label: 'Ensino médio', value: 'Ensino médio' }        
        ];


        const atividadesTerapeuticasOptions = [
        { label: 'ABA', value: 'ABA' },
        { label: 'Fisioterapia', value: 'Fisioterapia' },
        { label: 'Hidroterapia', value: 'Hidroterapia' },
        { label: 'Musicoterapia', value: 'Musicoterapia' },
        { label: 'Psicólogica', value: 'Psicólogica' },
        { label: 'Psicopedagogia', value: 'Psicopedagogia' },
        { label: 'TO', value: 'TO' },
        { label: 'Fonoaudiólogo', value: 'Fonoaudiólogo' },
        { label: 'Nenhum', value: 'Nenhum' },
        { label: 'Outros', value: 'Outros' }
        ];


        const rendaFamiliarOptions = [
        { label: 'Classe E', value: 'Classe E' },
        { label: 'Classe D', value: 'Classe D' },
        { label: 'Classe C', value: 'Classe C' },
        { label: 'Classe B', value: 'Classe B' },
        { label: 'Classe A', value: 'Classe A' }
        ];
        export default {

          components:{
            DateInput,  
            money:Money3Component 
          },
          watch:{
            'form.renda_pai':function($val){
             this.confereRenda();
           },
           'form.renda_mae':function($val){
             this.confereRenda();
           },
           'form.outra_renda':function($val){
             this.confereRenda();
           },
           'form.nivel_educacional':function($val){
          //this.form.ano_educacional = '';
        },
        'form.aluno_estuda':function($val){
          if($val === false){
            this.form.instituicao_educacional = '';
            this.form.tipo_instituicao_educacional = '';
            this.form.nivel_educacional = '';
            this.form.ano_educacional = '';
          }
        }
      },
      computed:{
        anosLetivos(){
          switch(this.form.nivel_educacional){
            case '':
            return [];
            break
            case 'Educação infantil':
            return [];
            break
            case 'Ensino fundamental':
            return [1,2,3,4,5,6,7,8,9];
            break
            case 'Ensino médio':
            return [1,2,3];
            break
          }
        }
      },
      methods:{
        confereRenda(){
          let total = parseFloat(this.form.renda_mae) + parseFloat(this.form.renda_pai) + parseFloat(this.form.outra_renda) ;          
          switch(true){
            case total > 0 && total <=1254:
            this.form.renda_familiar = 'Classe E'
            break;
            case total > 1254 && total <=2004:
            this.form.renda_familiar = 'Classe D'
            break;
            case total > 2004 && total <=8640:
            this.form.renda_familiar = 'Classe C'
            break;
            case total > 8640  && total <=11261:
            this.form.renda_familiar = 'Classe B'
            break;
            case total > 11261: 
            this.form.renda_familiar = 'Classe A'
            break;
          }

        }
      },
      data(){
        return {
         money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false,
          disableNegative: true,
          disabled: false,
          min: Number.MIN_SAFE_INTEGER,
          max: Number.MAX_SAFE_INTEGER,
          allowBlank: false,
          minimumNumberOfCharacters: 0,
        }
      }
    },
    props: ['id'],
    setup (props, context) {
      const { data, load: resourceLoad, pending: resourcePending, error: resourceError } = getModel(`alunos/${props.id}/fichas`);
      const { pending, error, create } = useCollection(`alunos/${props.id}/fichas`);
      const { triggerPositive, triggerNegative } = useNotify();

      const form = reactive({
        abertura: new Date().toISOString(),
        motivo_procura: '',
        encaminhamento: '',
        condicao_moradia: '',
        tipo_transporte: '',
        transporte_equoterapia: false,
        usf_ubs: false,
        dificuldade_tratamento: '',
        plano_saude: false,
        instituicao_educacional: '',
        atendimento_aee: false,
        atividade_complementar: ref(null),
        atividade_complementar_local:'',
        emprego_mae: '',
        emprego_pai: '',
        aluno_estuda:false,
        tipo_instituicao_educacional:'',
        nivel_educacional:'',
        ano_educacional:'',

        renda_pai:0,
        renda_mae:0,
        outra_renda:0,
        renda_familiar: '',
        atividade_lazer: '',
        frequencia_relacoes: ''
      });

      resourceLoad().then(() => {
        Object.assign(form, data.value);
      });

      const handleSubmit = async () => {
        const response = await create(form);
        if(response) {
          triggerPositive('Salvo com sucesso!');
          context.emit('created');
        } else {
          triggerNegative('Verifique se os campos estão corretos.');
        }
      }

      return {
        form, resourcePending, resourceError,
        pending, error,
        handleSubmit,
        transporteOptions,
        encaminhamentosOptions,
        moradiaOptions,
        atividadesTerapeuticasOptions,
        rendaFamiliarOptions,
        tipoEnsinoOptions,
        nivelOptions
      }
    }
  }
</script>

<style>

</style>