<template>
  <div class="q-pa-md q-my-lg">
    <div class="row justify-center">
      <div class="col col-lg-6">
        <q-card>
          <q-card-section>
            <q-form @submit="handleSubmit" class="q-pa-lg">
              <div class="row q-col-gutter-lg q-mb-md">
                <div class="col">
                  <q-input filled v-model="form.nome" label="Nome" :error-message="error?.errors?.nome?.[0]" :error="error?.errors?.nome?.length > 0"/>
                </div>
                <div class="col">
                  <q-file filled v-model="form.arquivo" label="Arquivo" :error-message="error?.errors?.arquivo?.[0]" :error="error?.errors?.arquivo?.length > 0"/>
                </div>
                <!-- <div class="col">
                  <q-select filled v-model="form.modelo" :options="modelos" option-value="id" option-label="nome" label="Modelo" emit-value map-options :hide-selected="modelosPending"
                  :error-message="error?.errors?.modelo?.[0]" :error="error?.errors?.modelo?.length > 0">
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-italic text-grey">
                          Nenhum modelo cadastrado
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div> -->
              </div>

              <div class="row q-col-gutter-lg q-mb-md">
                <div class="col">
                  <q-input filled type="textarea" v-model="form.descricao" label="Descrição" :error-message="error?.errors?.descricao?.[0]" :error="error?.errors?.descricao?.length > 0"/>
                </div>
              </div>

              <q-separator spaced="xl" />

              <div class="row justify-between">
                <q-btn color="red" label="Voltar" @click="$router.go(-1)" flat />
                <q-btn label="Salvar" type="submit" color="primary" :loading="pending"/>
              </div>
            </q-form>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

import getCollection from '@/composables/getCollection';
import useCollection from '@/composables/useCollection';
import useNotify from '@/composables/useNotify';
import createFormData from '@/composables/createFormData';

export default {
  props: ['id'],
  setup(props, context) {
    const { data: modelos, load: modelosLoad, pending: modelosPending } = getCollection('modelos');
    const { pending, error, create } = useCollection(`alunos/${props.id}/anexos`);
    const { triggerPositive, triggerNegative } = useNotify();

    modelosLoad();

    const initialState = reactive({
      nome: '',
      descricao: '',
      arquivo: null
    });

    const form = reactive({ ...initialState });

    const resetForm = () => {
      Object.assign(form, initialState);
    }

    const handleSubmit = async () => {
      const data = createFormData(form);
      const response = await create(data);
      if(response) {
        resetForm();
        triggerPositive('Criado com sucesso!');
      } else {
        triggerNegative('Verifique se os campos estão corretos.');
      }
    }

    return { 
      form, 
      pending, error,
      modelos, modelosPending,
      handleSubmit,
    }
  }
}
</script>

<style>

</style>