import Dialog from 'quasar/src/plugins/Dialog.js';

import useNotify from '@/composables/useNotify';

const { triggerPositive, triggerNegative } = useNotify();

function handleDelete(id) {
    Dialog.create({
        title: 'Confirmação',
        message: 'Deseja realmente deletar?',
        cancel: true,
        persistent: true
    }).onOk(async () => {
        this.pending = true;
        const response = await this.destroy(id);
        if (response) {
            this.reloadCollection();
            triggerPositive('Exclusão concluída com sucesso!');
        } else {
            triggerNegative('Não foi possível concluir a exclusão.');
        }
        this.pending = false;
    });
}

export default handleDelete