import openURL from 'quasar/src/utils/open-url.js';;

const getFileUrl = (folder, file) => {
    return `${process.env.VUE_APP_BASE_URL}/storage/${folder}/${file}`;
}

const downloadFile = (folder, file) => {
    openURL(getFileUrl(folder, file));
}


export { downloadFile, getFileUrl }