<template>
  <q-form @submit="handleSubmit" class="q-pa-lg">
    

    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col">
        <q-toggle filled v-model="form.bolsa_familia" label="Bolsa família" :error-message="error?.errors?.bolsa_familia?.[0]" :error="error?.errors?.bolsa_familia?.length > 0"/>
      </div>
      <div class="col">
        <q-toggle filled v-model="form.renda_cidada" label="Renda cidadã" :error-message="error?.errors?.renda_cidada?.[0]" :error="error?.errors?.renda_cidada?.length > 0"/>
      </div>
      <div class="col">
        <q-toggle filled v-model="form.renda_minima" label="Renda mínima" :error-message="error?.errors?.renda_minima?.[0]" :error="error?.errors?.renda_minima?.length > 0"/>
      </div>
    </div>

    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col">
        <q-toggle filled v-model="form.bpc" label="BPC idoso/deficiente" :error-message="error?.errors?.bpc?.[0]" :error="error?.errors?.bpc?.length > 0"/>
      </div>
      <div class="col">
        <q-input filled v-model="form.outro_programa" label="Outro programa" :error-message="error?.errors?.outro_programa?.[0]" :error="error?.errors?.outro_programa?.length > 0"/>
      </div>
          <div class="col"></div>
    </div>



    

    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col">
        <q-toggle v-model="form.cesta_basica" label="Cesta básica" />
      </div>
      <div class="col">
        <q-toggle v-model="form.aluguel_social" label="Aluguel social" />
      </div>
      <div class="col">
        <q-toggle v-model="form.vale_transporte" label="Vale transporte para tratamento" />
      </div>
    </div>

    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col">
        <q-input filled v-model="form.outro_beneficio" label="Outro benefício" :error-message="error?.errors?.outro_beneficio?.[0]" :error="error?.errors?.outro_beneficio?.length > 0"/>
      </div>
      <div class="col">
        <q-toggle v-model="form.carteira_transporte" label="Carteira de transporte gratuito" />
      </div>
    </div>

    <q-separator spaced="xl" />

    <div class="row justify-end">
      <q-btn label="Salvar" type="submit" color="primary" :loading="pending"/>
    </div>
  </q-form>
</template>

<script>
import { ref, reactive } from 'vue'

import getModel from '@/composables/getModel';
import useCollection from '@/composables/useCollection';
import useNotify from '@/composables/useNotify';

export default {
  props: ['id'],
  setup (props, context) {
    const { data, load: resourceLoad, pending: resourcePending, error: resourceError } = getModel(`alunos/${props.id}/fichas/beneficios`);
    const { pending, error, create } = useCollection(`alunos/${props.id}/fichas/beneficios`);
    const { triggerPositive, triggerNegative } = useNotify();

    const form = reactive({
      bolsa_familia: false,
      renda_cidada: false,
      renda_minima: false,
      bpc: false,
      outro_programa: '',
      cesta_basica: false,
      aluguel_social: false,
      vale_transporte: false,
      outro_beneficio: '',
      carteira_transporte: false
    });

    resourceLoad().then(() => {
      Object.assign(form, data.value);
       if(data.value.length !== 0) {
         context.emit('check','beneficios');
       }
    });

    const handleSubmit = async () => {
      const response = await create(form);
      if(response) {
        triggerPositive('Salvo com sucesso!');

         context.emit('check','beneficios');
       
      } else {
        triggerNegative('Verifique se os campos estão corretos.');
      }
    }

    return {
      form, resourcePending, resourceError,
      pending, error,
      handleSubmit
    }
  }
}
</script>

<style>

</style>