import { render } from "./App.vue?vue&type=template&id=78567866"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
script.render = render

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QTabs,QRouteTab,QDrawer,QPageContainer});
