<template>
  <div class="q-pa-md q-my-lg">
    <div class="row justify-center">
      <div class="col col-lg-4">
        <q-card>
          <q-card-section>
            <q-form @submit="handleSubmit" class="q-pa-lg">
              <div class="row q-col-gutter-lg q-mb-md">
                <div class="col">
                  <q-file filled v-model="foto" label="Foto" :error-message="error?.errors?.foto?.[0]" :error="error?.errors?.foto?.length > 0"/>
                </div>
              </div>

              <q-separator spaced="xl" />

              <div class="row justify-between">
                <q-btn color="red" label="Voltar" @click="$router.go(-1)" flat />
                <q-btn label="Salvar" type="submit" color="primary" :loading="pending"/>
              </div>
            </q-form>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";

import uploadFile from '@/composables/uploadFile';
import useNotify from '@/composables/useNotify';

export default {
  props: ['id'],
  setup(props, context) {
    const { upload, pending, error } = uploadFile();
    const { triggerPositive, triggerNegative } = useNotify();

    const foto = ref(null);

    const handleSubmit = async () => {
      console.log(foto.value)
      const response = await upload(`alunos/${props.id}/foto`, foto.value);

      if(response) {
        triggerPositive('Atualizado com sucesso!');
      } else {
        triggerNegative('Verifique se os campos estão corretos');
      }
    };

    return { 
      foto,
      pending, error,
      handleSubmit
    }
  }
}
</script>

<style>

</style>