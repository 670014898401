import FichaEdit from '../views/alunos/fichas/Edit';

const fichasRoutes = [
    {
        path: '/alunos/:id/ficha',
        name: 'Ficha.Edit',
        component: FichaEdit,
        props: true
    },
]

export default fichasRoutes;