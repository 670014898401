<template>
  <q-layout view="hHh LpR fFf">
    <q-header elevated class="bg-green-7 text-white" height-hint="98">
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="toggleLeftDrawer" v-if="showSidebarMenu"/>

        <q-toolbar-title>
          Equoterapia
        </q-toolbar-title>
      </q-toolbar>

      <q-tabs v-if="routeIncludes('alunos')" inline-label>
        <q-route-tab :to="{ name: 'Aluno.Index' }" label="Todos" icon="people" />
        <q-route-tab :to="{ name: 'Aluno.Ativo' }" label="Ativos" icon="sentiment_very_satisfied" />
        <q-route-tab :to="{ name: 'Aluno.Fila' }" label="Fila" icon="sentiment_neutral" />
        <q-route-tab :to="{ name: 'Aluno.Alta' }" label="Alta" icon="thumb_up_off_alt" />
        <q-route-tab :to="{ name: 'Aluno.Create' }" label="Cadastro" icon="add" />
      </q-tabs>

      <q-tabs v-if="routeIncludes('modelos')" inline-label>
        <q-route-tab :to="{ name: 'Modelo.Index' }" label="Modelos" icon="attach_file" />
        <q-route-tab :to="{ name: 'Modelo.Create' }" label="Adicionar" icon="add" />
      </q-tabs>

      <q-tabs v-if="routeIncludes('users')" inline-label>
        <q-route-tab :to="{ name: 'User.Index' }" label="Usuários" icon="assignment_ind" />
        <q-route-tab :to="{ name: 'User.Create' }" label="Adicionar" icon="add" />
      </q-tabs>
    </q-header>

    <!-- <q-drawer show-if-above v-model="leftDrawerOpen" side="left" bordered v-if="this.$route.meta.sidebar === true"> -->
      <q-drawer show-if-above v-model="leftDrawerOpen" side="left" bordered v-if="showSidebarMenu">
        <SidebarMenu />
      </q-drawer>

      <q-page-container>
        <router-view />
      </q-page-container>

    </q-layout>
  </template>

  <script>
    import { computed, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import moment from 'moment';
    import SidebarMenu from '@/components/layout/SidebarMenu';
    import {filter} from '@/boot/filters'

    export default {
      components: { SidebarMenu },
      methods:{
        dmy(date){
          if (date) {
            return moment(String(date)).format('DD/MM/YYYY')
          }
        }
      },
      setup (props, context) {
        const route = useRoute();
        const leftDrawerOpen = ref(false);
        const teste = ref(false);

        const toggleLeftDrawer = () => {
          leftDrawerOpen.value = !leftDrawerOpen.value
        };

        
        const showSidebarMenu = computed(() => {
          return route.name !== 'Login';
        });

        const shouldShowSidebar = computed(() => {
          return route.meta.sidebar !== false;
        });

        const routeIncludes = (path) => {
          return route.fullPath.includes(`/${path}`);
        };
        

        return { 
          leftDrawerOpen, 
          toggleLeftDrawer, 
          showSidebarMenu, routeIncludes, shouldShowSidebar,
          
        }
      }
    }
  </script>
