<template>
  <template v-if="form">
    <div class="row justify-center q-mb-md">
      <q-btn round :to="{ name: 'Aluno.Foto', params: { id: form.id } }">

        <q-avatar size="100px" v-if="form.foto">
          <img :src="getFileUrl('alunos/fotos', form.foto)">
        </q-avatar>
        <q-avatar size="100px" font-size="52px" color="teal" text-color="white" icon="person" v-else/>

        <q-tooltip anchor="center middle" self="center middle">
          Alterar imagem
        </q-tooltip>
      </q-btn>
    </div>

    <div class="row justify-center q-mb-md">
      <q-chip size="18px" :icon="getIcon(form.status)" class="capitalize">
        {{ form.status }}
      </q-chip>
      <q-chip size="18px" icon="attach_money" class="capitalize">
        {{ form.tipo }}
      </q-chip>
      <q-chip size="18px" icon="cake">
        {{ form.idade }} anos
      </q-chip>
      <q-btn outline rounded color="primary" label="Editar" icon="edit" :to="{ name: 'Aluno.Edit', params: { id: form.id } }"/>
    </div>

    <q-separator />

    <div class="row justify-center">
      <div class="col col-lg-6">
        <q-list>
          <q-item>
            <q-item-section>Nome: {{ form.nome }}</q-item-section>
          </q-item>

          <q-item>
            <q-item-section>
              <q-item-label>Data de nascimento: {{ $root.dmy(form.data_nascimento) }}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section>
              <q-item-label>CPF: {{ form.cpf }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>

      <div class="col col-lg-6">
        <q-list>
         

          <q-item>
            <q-item-section>Diagnóstico: {{ form.diagnostico?.DESCRABREV }}</q-item-section>
          </q-item>

          <q-item>
            <q-item-section>
              <q-item-label>RG: {{ form.rg }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>

    <q-separator />

    <div class="row justify-center">
      <div class="col col-lg-6">
        <q-list>
          <q-item>
            <q-item-section>Nome do responsável: {{ form.nome_responsavel }}</q-item-section>
          </q-item>

          <q-item>
            <q-item-section>
              <q-item-label>Telefone: {{ form.telefone }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>

      <div class="col col-lg-6">
        <q-list>
         

          <q-item>
            <q-item-section>
              <q-item-label>Email: {{ form.email }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>

    <template v-if="form.address">
      <q-separator />

      <div class="row justify-center">
        <div class="col col-lg-6">
          <q-list>
            <q-item>
              <q-item-section>CEP: {{ form.address.cep }}</q-item-section>
            </q-item>

            <q-item>
              <q-item-section>
                <q-item-label>Cidade: {{ form.address.cidade }}</q-item-label>
              </q-item-section>
            </q-item>

            <q-item>
              <q-item-section>
                <q-item-label>Rúa: {{ form.address.rua }}</q-item-label>
              </q-item-section>
            </q-item>

            <q-item>
              <q-item-section>
                <q-item-label>Complemento: {{ form.address.complemento }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>

        <div class="col col-lg-6">
          <q-list>
            <q-item>
              <q-item-section>Estado: {{ form.address.estado }}</q-item-section>
            </q-item>

            <q-item>
              <q-item-section>
                <q-item-label>Bairro: {{ form.address.bairro }}</q-item-label>
              </q-item-section>
            </q-item>

            <q-item>
              <q-item-section>
                <q-item-label>Número: {{ form.address.numero }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </template>
  </template>

  <ListSkeleton v-if="resourcePending" />
</template>

<script>
import getModel from '@/composables/getModel';
import ListSkeleton from '@/components/layout/skeletons/ListSkeleton';
import { getFileUrl } from '@/composables/getFile';

export default {
  props: ['id'],
  components: { ListSkeleton },
  setup(props, context) {
    const { data: form, load: resourceLoad, pending: resourcePending, error: resourceError } = getModel(`alunos/${props.id}`);

    resourceLoad();

    const getIcon = (status) => {
      if (status === 'fila') {
        return 'sentiment_neutral';
      } else if (status === 'ativo') {
        return 'sentiment_very_satisfied';
      } else if (status === 'alta') {
        return 'thumb_up_off_alt';
      }
    }

    return { 
      form, resourcePending, resourceError,
      getIcon, getFileUrl
    }
  }
}
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>