<template>
  <div class="q-mt-lg">
    <div v-if="dashboard">
      <div class="row justify-center q-mb-lg">
        <div class="col-10">
          <div class="text-h5">Alunos</div>

          <q-separator spaced="lg"/>

          <div class="row justify-center q-col-gutter-md">
            <div class="col-12 col-sm-6 col-lg-3">
              <q-card>
                <q-card-section horizontal class="bg-grey text-white">
                  <q-card-section class="self-center">
                    <q-icon name="people" style="font-size: 3rem;"/>
                  </q-card-section>

                  <q-card-section>
                    <div class="text-h5">Todos</div>
                    <div class="text-h5">{{ dashboard.total.alunos.todos }}</div>
                  </q-card-section>
                </q-card-section>
              </q-card>
            </div>
            
            <div class="col-12 col-sm-6 col-lg-3">
              <q-card>
                <q-card-section horizontal class="bg-primary text-white">
                  <q-card-section class="self-center">
                    <q-icon name="sentiment_very_satisfied" style="font-size: 3rem;"/>
                  </q-card-section>

                  <q-card-section>
                    <div class="text-h5">Ativos</div>
                    <div class="text-h5">{{ dashboard.total.alunos.ativos }}</div>
                  </q-card-section>
                </q-card-section>
              </q-card>
            </div>
            
            <div class="col-12 col-sm-6 col-lg-3">
              <q-card>
                <q-card-section horizontal class="bg-warning text-white">
                  <q-card-section class="self-center">
                    <q-icon name="sentiment_neutral" style="font-size: 3rem;" />
                  </q-card-section>

                  <q-card-section>
                    <div class="text-h5">Fila</div>
                    <div class="text-h5">{{ dashboard.total.alunos.fila }}</div>
                  </q-card-section>
                </q-card-section>
              </q-card>
            </div>

            <div class="col-12 col-sm-6 col-lg-3">
              <q-card>
                <q-card-section horizontal class="bg-positive text-white">
                  <q-card-section class="self-center">
                    <q-icon name="thumb_up_off_alt" style="font-size: 3rem;" />
                  </q-card-section>

                  <q-card-section>
                    <div class="text-h5">Alta</div>
                    <div class="text-h5">{{ dashboard.total.alunos.alta }}</div>
                  </q-card-section>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-center q-col-gutter-md">
        <div class="col-10 col-md-5 q-gutter-y-md">
          <div class="text-h5">Modelos</div>

          <q-separator spaced="lg"/>
          
          <q-card>
            <q-card-section horizontal class="bg-grey text-white">
              <q-card-section class="self-center">
                <q-icon name="insert_drive_file" style="font-size: 3rem;"/>
              </q-card-section>

              <q-card-section>
                <div class="text-h5">Total</div>
                <div class="text-h5">{{ dashboard.total.modelos }}</div>
              </q-card-section>
            </q-card-section>
          </q-card>

          <q-list bordered separator>
            <q-item clickable v-ripple v-for="modelo in dashboard.recentes.modelos" :key="modelo.id">
              <q-item-section>
                <q-item-label>{{ modelo.nome }}</q-item-label>
                <q-item-label caption lines="1">{{ `${modelo.descricao.substring(0,50)}...` }}</q-item-label>
              </q-item-section>

              <q-item-section side>
                <q-badge outline color="primary">
                  {{ modelo.created_at }}
                </q-badge>
              </q-item-section>
            </q-item>
          </q-list>
        </div>

        <div class="col-10 col-md-5 q-gutter-y-md">
          <div class="text-h5">Usuários</div>

          <q-separator spaced="lg"/>

          <q-card>
            <q-card-section horizontal class="bg-grey text-white">
              <q-card-section class="self-center">
                <q-icon name="assignment_ind" style="font-size: 3rem;"/>
              </q-card-section>

              <q-card-section>
                <div class="text-h5">Total</div>
                <div class="text-h5">{{ dashboard.total.users }}</div>
              </q-card-section>
            </q-card-section>
          </q-card>

          <q-list bordered separator>
            <q-item clickable v-ripple v-for="user in dashboard.recentes.users" :key="user.id">
              <q-item-section avatar>
                <!-- <q-avatar>
                  <img :src="`https://cdn.quasar.dev/img/avatar2.jpg`">
                </q-avatar> -->
                <!-- <q-avatar icon="account_circle" font-size="40px"/> -->
                <q-avatar color="primary" text-color="white">
                  A
                </q-avatar>
              </q-item-section>

              <q-item-section>
                <q-item-label>{{ user.nome_completo }}</q-item-label>
                <q-item-label caption lines="1">{{ user.email }}</q-item-label>
              </q-item-section>

              <q-item-section side>
                <!-- <q-icon name="chat_bubble" color="grey" /> -->
                <q-badge outline color="primary">
                  {{ user.created_at }}
                </q-badge>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </div>

    <q-inner-loading :showing="resourcePending">
      <q-spinner color="primary" size="5em"/>
    </q-inner-loading>
  </div>
</template>

<script>
import getModel from '@/composables/getModel';

export default {
  setup(props, context) {
    const { data: dashboard, load: resourceLoad, pending: resourcePending, error: resourceError } = getModel(`dashboard`);

    resourceLoad();

    return {
      dashboard, resourcePending
    }
  }
}
</script>
