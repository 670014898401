import AlunoCreate from '../views/alunos/Create';
import AlunoEdit from '../views/alunos/Edit';
import AlunoShow from '../views/alunos/Show';

import AlunoIndex from '../views/alunos/Index';
import AlunoAtivo from '../views/alunos/Ativo';
import AlunoFila from '../views/alunos/Fila';
import AlunoAlta from '../views/alunos/Alta';

import AlunoFoto from '../views/alunos/Foto';

const alunosRoutes = [
    {
        path: '/alunos',
        name: 'Aluno.Index',
        component: AlunoIndex
    },
    {
        path: '/alunos/create',
        name: 'Aluno.Create',
        component: AlunoCreate
    },
    {
        path: '/alunos/:id',
        name: 'Aluno.Show',
        component: AlunoShow,
        props: true
    },
    {
        path: '/alunos/:id/edit',
        name: 'Aluno.Edit',
        component: AlunoEdit,
        props: true
    },
    {
        path: '/alunos/ativo',
        name: 'Aluno.Ativo',
        component: AlunoAtivo
    },
    {
        path: '/alunos/fila',
        name: 'Aluno.Fila',
        component: AlunoFila
    },
    {
        path: '/alunos/alta',
        name: 'Aluno.Alta',
        component: AlunoAlta
    },
    {
        path: '/alunos/:id/foto',
        name: 'Aluno.Foto',
        component: AlunoFoto,
        props: true
    },
]

export default alunosRoutes;