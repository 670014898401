import moment from 'moment';


 export default filter => {
 	function date(value) {
 		if (value) {
 			return moment(String(value)).format('DD/MM/YYYY HH:mm')
 		}
 	}
 	function dmy(value) {
 		if (value) {
 			return moment(String(value)).format('DD/MM/YYYY')
 		}
 	}
  //   'hi': function(value) {
  //   	console.log(value,
  //       if (value) {
  //           return moment(String(value)).format('HH:mm')
  //       }
  //   },

 	// 'money': function(value) {
 	// 	if (value) {
 	// 		let val = (value/1).toFixed(2).replace('.': ',')
 	// 		return 'R$ '+(val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."))
 	// 	}
 	// },

 	// 'tempo': function(value) {
 	// 	if (value) {
 	// 		if( Number(value) < 60 ){
 	// 			return value + (value == 1 ? ' segundo' :' segundos',
 	// 		}else{

  //               let minutos = Number(value)/60;
  //               let min_inteiros = Math.floor(minutos,
  //               let segundos_fracao = minutos % 1;
  //               let segundos_inteiros = segundos_fracao * 60;
  //               segundos_inteiros = Math.floor(segundos_inteiros)
  //               if(segundos_fracao > 0){
  //                   return min_inteiros + "min " + segundos_inteiros + "seg ";
  //               } else {
  //                   return min_inteiros + "min"; 
  //               }
 	// 		}
 	// 	}
 	// },

 	// 'distancia': function(value) {
 	// 	if (value) {
 	// 		if( Number(value) < 1000 ){
  //                return value + (value == 1 ? ' metro' :' metros',
 	// 		}else{
 	// 			return (parseFloat(Number(value)/1000) + (parseFloat(Number(value)/1000) ==1 ? ' kilometro' : ' kilometros')).replace('.',',',
  //           }
 	// 	}
  //    },

  //    'metros': function(value) {
  //       if (value) {
  //           if(value > 1000){
  //               value = 1000;
  //           }
  //           return value.toFixed(2).replace('.',',',
  //       }
  //   },
     
  //   'metros_round': function(value) {
  //       if (value) {
  //           if(value > 1000){
  //               value = 1000;
  //           }
  //           return String(Math.round(value)).replace('.',',',
  //       }
  //   },
     

 	// 'repeticao': function(value) { 		
 	// 	if (value) {
 	// 		return Number(value) + (Number(value) ==1 ? ' vez' : ' vezes',
 	// 	}
 	// },

  //   'decimal': function(value) {       
  //       if (value) {
  //           return (value.toFixed(2)).replace('.',',',
  //       }else{
  //           return 0;
  //       }
  //   },

  //   'floor': function(value) {       
  //       if (value) {
  //           return Math.floor(value,
  //       }else{
  //           return 0;
  //       }
  //   },

  //   'pace': function(value) {       
  //       if (value) {
  //           let minutes = Math.floor(value,
  //           let seconds_decimal = (value % 1).toFixed(2,
  //           let seconds = Math.floor(60 * seconds_decimal,
  //           seconds = String(seconds).padStart(2, '0',
  //           minutes = String(minutes).padStart(2, '0',
  //           return minutes + ":"+seconds;
  //       } else {
  //           return '00' + ":"+'00';
  //       }
  //   },  

  //   'pace_notado': function(value) {       
  //       if (value) {
  //           let minutes = Math.floor(value,
  //           let seconds_decimal = (value % 1).toFixed(2,
  //           let seconds = Math.floor(60 * seconds_decimal,
  //           seconds = String(seconds).padStart(2, '0',
  //           return minutes + "’"+seconds;
  //       } else {
  //           return '00' + "’"+'00';
  //       }
  //   },  

  //   'km': function(value) {       
  //       if (value) {
  //           return ((value/1000).toFixed(2)).replace('.',',',
  //       } else {
  //           return "0,00";
  //       }
  //   },

  //   'duracao': function(value) { 		
  //       if (value) {
  //           var ms = value;
  //          var days = Math.floor(ms / (24*60*60*1000),
  //          var daysms=ms % (24*60*60*1000,
  //          var hours = Math.floor((daysms)/(60*60*1000),
  //          var hoursms=ms % (60*60*1000,
  //          var minutes = Math.floor((hoursms)/(60*1000),
  //          var minutesms=ms % (60*1000,
  //          var sec = Math.floor((minutesms)/(1000),

  //          let pad = function(n){ return n < 10 ? '0' + n : n; };

  //          if(hours > 0){
  //              return pad(hours) + ":" + pad(minutes) + ":" + pad(sec,
  //          } else {
  //              return pad(minutes) + ":" + pad(sec,
  //          }
  //       } else {
  //          return '00:00';
  //      }
  //   },

  //   'duracao_ms': function(value) { 		
  //       if (value) {
  //           var ms = value;
  //          var days = Math.floor(ms / (24*60*60*1000),
  //          var daysms=ms % (24*60*60*1000,
  //          var hours = Math.floor((daysms)/(60*60*1000),
  //          var hoursms=ms % (60*60*1000,
  //          var minutes = Math.floor((hoursms)/(60*1000),
  //          var minutesms=ms % (60*1000,
  //          var sec = Math.floor((minutesms)/(1000),
  //          var sec_ms = ms % (1000,
  //          let pad = function(n){ return n < 10 ? '0' + n : n; };
  //          var sec_ms = String(sec_ms).padStart(3,'0')
  //          return minutes + ":" + pad(sec) + ":" + String(sec_ms).substring(0, 2,

  //       } else {
  //          return '0:00:00';
  //      }
  //   },

  //   'mes_nome': function(value) {       
  //       switch(value){
  //           case 1:
  //               return 'Jan';
  //           break;
  //           case 2:
  //               return 'Fev';
  //           break;
  //           case 3:
  //               return 'Mar';
  //           break;
  //           case 4:
  //               return 'Abr';
  //           break;
  //           case 5:
  //               return 'Mai';
  //           break;
  //           case 6:
  //               return 'Jun';
  //           break;
  //           case 7:
  //               return 'Jul';
  //           break;
  //           case 8:
  //               return 'Ago';
  //           break;
  //           case 9:
  //               return 'Set';
  //           break;
  //           case 10:
  //               return 'Out';
  //           break;
  //           case 11:
  //               return 'Nov';
  //           break;
  //           case 12:
  //               return 'Dez';
  //           break;
  //       }
  //   },


  //   'intensidade_nome': function(value) {
  //       if (value) {
  //           switch (value) {
  //               case 1:
  //                   return 'Fácil'
  //               break;
  //               case 2:
  //                   return 'Médio'
  //               break;
  //               case 3:
  //                   return 'Intenso'
  //               break;
  //               case 4:
  //                   return 'Difícil'
  //               break;
            
  //               default:
  //                   break;
  //           }
  //       }
  //   },
 	
 }