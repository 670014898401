<template>
  <div class="q-pa-md q-my-sm q-gutter-lg">
    <div class="row justify-center" v-for="row in rows" :key="row.id">
      <div class="col col-lg-4">
        <q-card class="my-card">
          <q-card-section class="bg-primary text-white">
            <q-chip square icon="event">{{ row.created_at }}</q-chip>
            <div class="text-h4">{{ row.nome }}</div>
            <div class="text-h6">{{ row.descricao }}</div>
          </q-card-section>

          <q-separator />

          <q-card-actions align="right">
            <q-btn color="primary" flat icon="download" @click="downloadFile('modelos/arquivos', row.arquivo)"/>
            <!-- <q-btn color="primary" flat icon="download" href="http://localhost:8000/storage/anexos/arquivos/1617301668_EVOLU%C3%87%C3%83O%20DI%C3%81RIA.pdf" download/> -->
            <!-- <q-btn color="primary" flat icon="edit" :to="{ name: 'Modelo.Edit', params: { id: row.id } }"/> -->
            <q-btn color="negative" flat icon="delete" @click="handleDelete(row.id)"/>
          </q-card-actions>
        </q-card>
      </div>
    </div>

    <div class="row justify-center" v-if="meta?.total === 0">
      <div class="col col-lg-4">
        <q-card class="my-card">
          <q-card-section>
            <div class="text-h5 text-center">Nenhum modelo foi encontrado.</div>
          </q-card-section>
        </q-card>
      </div>
    </div>

    <q-inner-loading :showing="pending">
      <q-spinner color="primary" size="5em"/>
    </q-inner-loading>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

import getCollection from '@/composables/getCollection';
import useModel from '@/composables/useModel';
import { downloadFile } from '@/composables/getFile';
import handleDelete from '@/composables/handleDelete';

export default {
  setup (props, context) {
    const { data, meta, pending, error, load } = getCollection('modelos');
    const { destroy } = useModel('modelos');

    const reloadCollection = async () => {
      await load();
    }

    onMounted(() => {
      reloadCollection();
    });

    return {
      rows: data, pending, error, meta,
      reloadCollection, handleDelete,
      downloadFile, destroy, 
    }
  }
}
</script>

<style>

</style>