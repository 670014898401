import { render } from "./Login.vue?vue&type=template&id=79c965ed"
import script from "./Login.vue?vue&type=script&lang=js"
export * from "./Login.vue?vue&type=script&lang=js"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QCard,QCardSection,QForm,QInput,QIcon,QBtn});
