import { render } from "./Detalhes.vue?vue&type=template&id=c1992226&scoped=true"
import script from "./Detalhes.vue?vue&type=script&lang=js"
export * from "./Detalhes.vue?vue&type=script&lang=js"

import "./Detalhes.vue?vue&type=style&index=0&id=c1992226&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-c1992226"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QAvatar,QTooltip,QChip,QSeparator,QList,QItem,QItemSection,QItemLabel});
