<template>
  <q-list>
    <!-- <q-img class="absolute-top" src="https://cdn.quasar.dev/img/material.png" style="height: 150px">
          <div class="absolute-bottom bg-transparent">
            <q-avatar size="56px" class="q-mb-sm">
              <img src="https://cdn.quasar.dev/img/boy-avatar.png">
            </q-avatar>
            <div class="text-weight-bold">Razvan Stoenescu</div>
            <div>@rstoenescu</div>
          </div>
        </q-img> -->
    <q-item class="q-pa-xl">
      <template v-if="user">
        <q-item-section avatar>
          <!-- <q-avatar>
            <img src="https://cdn.quasar.dev/img/boy-avatar.png">
          </q-avatar> -->
          <!-- <q-avatar icon="account_circle" font-size="40px"/> -->
          <q-avatar color="primary" text-color="white">
            {{ user.nome.charAt(0) }}
          </q-avatar>
        </q-item-section>

        <q-item-section v-if="user">
          <q-item-label class="text-weight-bold">{{ user.nome_completo }}</q-item-label>
          <q-item-label>{{ user.email }}</q-item-label>
        </q-item-section>

        <!-- <q-item-section side top>
          <q-badge label="10k" />
        </q-item-section> -->
      </template>

      <q-inner-loading :showing="resourcePending">
        <q-spinner color="primary" size="5em"/>
      </q-inner-loading>
    </q-item>
    
    <q-separator />

    <MenuItem icon="home" label="Home" route="Home" />

    <MenuItem icon="people" label="Praticantes" route="Aluno.Index" />

    <MenuItem icon="insert_drive_file" label="Modelos" route="Modelo.Index" />

    <q-expansion-item icon="admin_panel_settings" label="Administrador" :content-inset-level="0" :header-inset-level="0" expand-separator>
      <MenuItem label="Usuários" route="User.Index" />
    </q-expansion-item>

    <!-- <q-expansion-item icon="settings" label="Configurações" :content-inset-level="0" :header-inset-level="0" expand-separator>
      <MenuItem label="Senha" route="Home" />
    </q-expansion-item> -->

    <q-item clickable v-ripple @click="handleClick" v-if="token">
      <q-item-section avatar>
        <q-icon name="logout" />
      </q-item-section>

      <q-item-section>Logout</q-item-section>
    </q-item>
  </q-list>
</template>

<script>
import { computed, onMounted, onUnmounted } from 'vue';

import getModel from '@/composables/getModel';
import MenuItem from '@/components/ui/MenuItem';
import authStore from "@/store/auth";

export default {
  components: { MenuItem },
  setup(props, context) {
    const { data, load: resourceLoad, pending: resourcePending, error: resourceError } = getModel(`user`);
    
    const token = computed(() => authStore.getters.token);

    resourceLoad();

    const handleClick  = async () => {
      await authStore.dispatch('logout');
    }

    return {
      user: data, resourcePending, resourceError,
      token,
      handleClick
    }
  }
}
</script>

<style>

</style>