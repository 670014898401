<template>
  <div class="row justify-center q-col-gutter-lg q-mb-md">
    <div class="col col-lg-6 q-gutter-md">
      <q-skeleton :type="type" />
      <q-skeleton :type="type" />
      <q-skeleton :type="type" />
    </div>
    <div class="col col-lg-6 q-gutter-md">
      <q-skeleton :type="type" />
      <q-skeleton :type="type" />
      <q-skeleton :type="type" />
    </div>
  </div>
  <q-separator spaced="xl"/>
  <div class="row justify-center q-col-gutter-lg q-mb-md">
    <div class="col col-lg-6 q-gutter-md">
      <q-skeleton :type="type" />
      <q-skeleton :type="type" />
    </div>
    <div class="col col-lg-6 q-gutter-md">
      <q-skeleton :type="type" />
      <q-skeleton :type="type" />
    </div>
  </div>
  <q-separator spaced="xl"/>
  <div class="row justify-center q-col-gutter-lg">
    <div class="col col-lg-6 q-gutter-md">
      <q-skeleton :type="type" />
      <q-skeleton :type="type" />
      <q-skeleton :type="type" />
    </div>
    <div class="col col-lg-6 q-gutter-md">
      <q-skeleton :type="type" />
      <q-skeleton :type="type" />
      <q-skeleton :type="type" />
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return { type: 'rect' }
  }
}
</script>

<style>

</style>