import Notify from 'quasar/src/plugins/Notify.js';;

const useNotify = () => {
    const triggerPositive = (message) => {
        Notify.create({
            type: 'positive',
            message: message
        });
    };
  
    const triggerNegative = (message) => {
        Notify.create({
            type: 'negative',
            message: message
        });
    };

    return { triggerPositive, triggerNegative }
}

export default useNotify