import { render } from "./Diagnostico.vue?vue&type=template&id=5fc76806"
import script from "./Diagnostico.vue?vue&type=script&lang=js"
export * from "./Diagnostico.vue?vue&type=script&lang=js"
script.render = render

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QSelect,QToggle,QBtn,QSeparator});
