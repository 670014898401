import { reactive, toRefs } from "vue";
import axios from 'axios';

const useCollection = (resource) => {

    const initialState = reactive({
        response: null,
        pending: false,
        error: null,
    });

    const state = reactive({ ...initialState });

    const resetState = () => {
        Object.assign(state, initialState);
    }

    const create = async (model) => {
        resetState();
        state.pending = true;

        try {
            const response = await axios.post(`/${resource}`, model);
           // console.log(response);
            state.response = response;
            return response;
        } catch (error) {
            console.log(error.message);
            console.log(error.response.data);
            console.log(error.response.data.message);
            state.error = error.response.data
        } finally {
            state.pending = false;
        }
    }

    return { ...toRefs(state), create }
}

export default useCollection