
import 'quasar/dist/quasar.css'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import LoadingBar from 'quasar/src/plugins/LoadingBar.js';;
import langPt from 'quasar/lang/pt-BR'
// To be used on app.use(Quasar, { ... })
export default {
  config: {
    loadingBar: {
      color: 'primary',
      size: '8px',
      // position: 'bottom'
    }
  },
  lang:langPt,
  plugins: {
    Notify,
    Dialog,
    LoadingBar
  }
}