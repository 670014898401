<template>
  <div class="row justify-center q-mb-lg">
    <q-btn color="green" icon="add" label="Adicionar novo anexo" :to="{ name: 'Anexo.Create' }" />
  </div>
  
  <q-list bordered separator v-if="data.length">
    <q-item v-for="anexo in data" :key="anexo.id">
      <q-item-section>
        <q-item-label>{{ anexo.nome }}</q-item-label>
        <q-item-label caption>{{ anexo.descricao }}</q-item-label>
      </q-item-section>
      <q-item-section bottom side>
        <q-chip square icon="event">{{ anexo.created_at }}</q-chip>
        <div class="q-gutter-xs">
          <q-btn color="primary" flat icon="download" @click="downloadFile('alunos/anexos', anexo.arquivo)"/>
          <!-- <q-btn color="primary" flat icon="edit" :to="{ name: 'Anexo.Edit', params: { id: anexo.id } }"/> -->
          <q-btn color="negative" flat icon="delete" @click="handleDelete(anexo.id)"/>
        </div>
      </q-item-section>
    </q-item>
  </q-list>

  <div class="q-pa-md flex flex-center" v-if="resourcePending">
    <q-circular-progress indeterminate size="50px" color="lime" class="q-ma-md" />
  </div>
</template>

<script>
import { useQuasar } from 'quasar';

import getCollection from '@/composables/getCollection';
import useModel from '@/composables/useModel';
import useNotify from '@/composables/useNotify';
import { downloadFile } from '@/composables/getFile';

export default {
  props: ['id'],
  setup(props, context) {
    const $q = useQuasar();

    const { data, load: resourceLoad, pending: resourcePending, error: resourceError } = getCollection(`alunos/${props.id}/anexos`);
    const { destroy } = useModel(`anexos`);
    const { triggerPositive, triggerNegative } = useNotify();

    resourceLoad();

    const handleDelete = async (id) => {
      $q.dialog({
        title: 'Confirmação',
        message: 'Deseja realmente deletar este anexo?',
        cancel: true,
        persistent: true
      }).onOk(async () => {
        const response = await destroy(id);
        if(response) {
          resourceLoad();
          triggerPositive('Exclusão concluída com sucesso!');
        } else {
          triggerNegative('Não foi possível concluir a exclusão.');
        }
      });
    }

    return { 
      data, resourcePending, resourceError,
      downloadFile, handleDelete
    }
  }
}
</script>

<style>

</style>