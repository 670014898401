import { render } from "./Ficha.vue?vue&type=template&id=002e7cfb"
import script from "./Ficha.vue?vue&type=script&lang=js"
export * from "./Ficha.vue?vue&type=script&lang=js"
script.render = render

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QItemLabel,QSeparator,QBtn,QCircularProgress});
