import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home';
import Login from '@/views/auth/Login';

import NotFound from '@/views/errors/NotFound.vue';

import alunosRoutes from './alunos';
import usersRoutes from './users';
import modelosRoutes from './modelos';
import fichasRoutes from './fichas';
import anexosRoutes from './anexos';

import authStore from "@/store/auth";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // meta: { sidebar: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{ sidebar: false }
  },
  {
    path: '/:catchAll(.*)',
    // redirect: { name: 'NotFound' }
    name: 'NotFound',
    component: NotFound
  },
].concat(alunosRoutes, usersRoutes, modelosRoutes, fichasRoutes, anexosRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  let token = authStore.getters.token;

  if (to.name !== 'Login' && !token) {
    next({ name: 'Login' })
  } else if (to.name === 'Login' && token) {
    next(false)
    router.back()
  } else {
    next()
  }
});

export default router
