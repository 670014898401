<template>
  <div class="q-pa-md q-my-lg">
    <div class="row justify-center">
      <div class="col col-lg-6">
        <q-card v-if="form">
          <q-card-section>
            <q-form @submit="handleSubmit" class="q-pa-lg">
              <div class="row q-col-gutter-lg q-mb-md">
                <div class="col">
                  <q-input filled v-model="form.nome" label="Nome *" :error-message="error?.errors?.nome?.[0]" :error="error?.errors?.nome?.length > 0"/>
                </div>
                <div class="col">
                  <q-input filled v-model="form.sobrenome" label="Sobrenome *" :error-message="error?.errors?.sobrenome?.[0]" :error="error?.errors?.sobrenome?.length > 0"/>
                </div>
                <div class="col">
                  <q-select filled v-model="form.role.id" :options="roles" option-value="id" option-label="nome" label="Cargo *" emit-value map-options :hide-selected="rolesPending"
                  :error-message="error?.errors?.['role.id']?.[0]" :error="error?.errors?.['role.id']?.length > 0"/>
                </div>
              </div>

              <div class="row q-col-gutter-lg q-mb-md">
                <div class="col">
                  <q-input filled v-model="form.email" label="Email *" :error-message="error?.errors?.email?.[0]" :error="error?.errors?.email?.length > 0"/>
                </div>
                <div class="col">
                  <q-input filled v-model="form.password" label="Senha" :type="isPwd ? 'password' : 'text'" :error-message="error?.errors?.password?.[0]" :error="error?.errors?.password?.length > 0">
                    <template v-slot:append>
                      <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd"/>
                    </template>
                  </q-input>
                </div>
              </div>

              <q-separator spaced="xl" />

              <div class="row justify-between">
                <q-btn color="red" label="Voltar" @click="$router.go(-1)" flat />
                <q-btn label="Salvar" type="submit" color="primary" :loading="pending" />
              </div>
            </q-form>
          </q-card-section>
        </q-card>

        <q-inner-loading :showing="!form">
          <q-spinner color="primary" size="5em"/>
        </q-inner-loading>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

import getCollection from '@/composables/getCollection';
import getModel from '@/composables/getModel';
import useModel from '@/composables/useModel';
import useNotify from '@/composables/useNotify';

export default {
  props: ['id'],
  setup(props, context) {
    const { data: form, load: resourceLoad, pending: resourcePending, error: resourceError } = getModel(`users/${props.id}`);
    const { data: roles, load: rolesLoad, pending: rolesPending } = getCollection('roles');
    const { pending, error, update } = useModel('users');
    const { triggerPositive, triggerNegative } = useNotify();
    const isPwd = ref(true);

    resourceLoad();
    rolesLoad();

    const handleSubmit = async () => {
      const response = await update(props.id, form.value);
      if(response) {
        triggerPositive('Atualizado com sucesso!');
      } else {
        triggerNegative('Verifique se os campos estão corretos');
      }
    };

    return { 
      form, resourcePending, resourceError,
      roles, rolesPending,
      pending, error,
      handleSubmit,
      isPwd
    }
  }
}
</script>

<style>

</style>