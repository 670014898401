import UserIndex from '../views/users/Index';
import UserCreate from '../views/users/Create';
import UserEdit from '../views/users/Edit';

const usersRoutes = [
    {
        path: '/users',
        name: 'User.Index',
        component: UserIndex
    },
    {
        path: '/users/create',
        name: 'User.Create',
        component: UserCreate
    },
    {
        path: '/users/:id',
        name: 'User.Edit',
        component: UserEdit,
        props: true
    },
]

export default usersRoutes;