<template>
  <div class="q-pa-md">
    <div class="row justify-center">
      <div class="col col-lg-6">
        <q-table :rows="rows" :columns="columns" :loading="pending" row-key="id" v-model:pagination="pagination" @request="onRequest" :rows-per-page-options="[0]" :filter="filter"
          no-data-label="Nenhum dado cadastrado."
          no-results-label="Nenhum dado encontrado."
          loading-label="Carregando..."
        >
          <template v-slot:top-right>
            <q-input dense debounce="300" v-model="filter" placeholder="Pesquisar">
              <template v-slot:append>
                <q-icon name="close" @click="filter = ''" class="cursor-pointer" />
                <q-icon name="search" />
              </template>
            </q-input>
          </template>

          <template v-slot:no-data="{ icon, message, filter }">
            <div class="full-width row flex-center q-gutter-sm">
              <span>
                {{ message }}
              </span>
              <q-icon size="2em" :name="filter ? 'filter_b_and_w' : icon" />
            </div>
          </template>

          <template v-slot:body-cell-action="props">
            <q-td :props="props">
              <q-btn color="primary" icon-right="list_alt" no-caps flat dense :to="{ name: 'Aluno.Show', params: { id: props.row.id } }"/>
              <q-btn color="negative" icon-right="delete" no-caps flat dense @click="handleDelete(props.row.id)"/>
            </q-td>
          </template>
        </q-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';

import getCollection from '@/composables/getCollection';
import columns from '@/composables/pages/alunos/columns';
import handleDelete from '@/composables/pages/alunos/handleDelete';

export default {
  setup () {
    const { data, meta, pending, error, load } = getCollection('alunos');

    const filter = ref('');
    const pagination = ref({
      sortBy: 'nome',
      descending: false,
      page: 1,
      rowsPerPage: 15,
    });
    const params = reactive({
      status: 'alta',
    });

    const reloadCollection = async () => {
      await load({ ...pagination.value, filter: filter.value, ...params });
      pagination.value.rowsNumber = meta.value.total;
    }

    const onRequest = (props) => {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;

      pagination.value.page = page
      pagination.value.rowsPerPage = rowsPerPage
      pagination.value.sortBy = sortBy
      pagination.value.descending = descending
      filter.value = props.filter

      reloadCollection();
    }

    onMounted(() => {
      onRequest({
        pagination: pagination.value
      });
    });

    return {
      columns, 
      rows: data, pending, error, meta,
      reloadCollection, handleDelete,
      pagination, onRequest, filter
    }
  }
}
</script>

<style>

</style>