import axios from 'axios';

const endereco = {
    busca(cep){ 
        return axios.get(`https://ws.apicep.com/cep/${cep}.json`,{
          headers: { 
             "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Authorization", 
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE" ,
          "Content-Type": "application/json;charset=UTF-8"    
         },
     })
    }
};


export default endereco