import { render } from "./SidebarMenu.vue?vue&type=template&id=1882ea0c"
import script from "./SidebarMenu.vue?vue&type=script&lang=js"
export * from "./SidebarMenu.vue?vue&type=script&lang=js"
script.render = render

export default script
import QList from 'quasar/src/components/item/QList.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QImg,QAvatar,QItem,QItemSection,QItemLabel,QBadge,QInnerLoading,QSpinner,QSeparator,QExpansionItem,QIcon});qInstall(script, 'directives', {Ripple});
