import { render } from "./Ficha.vue?vue&type=template&id=a8f01b06"
import script from "./Ficha.vue?vue&type=script&lang=js"
export * from "./Ficha.vue?vue&type=script&lang=js"
script.render = render

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QSelect,QSeparator,QToggle,QInput,QField,QBtn});
