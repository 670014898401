import { render } from "./Alta.vue?vue&type=template&id=0259f912"
import script from "./Alta.vue?vue&type=script&lang=js"
export * from "./Alta.vue?vue&type=script&lang=js"
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QInput,QIcon,QTd,QBtn});
