<template>
  <q-form @submit="handleSubmit" class="q-pa-lg">
    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col">
        <q-toggle v-model="form.cras" label="CRAS" />
      </div>
      <div class="col">
        <q-toggle v-model="form.creas" label="CREAS" />
      </div>
      <div class="col">
        <q-toggle v-model="form.acolhimento_institucional" label="Acolhimento institucional" />
      </div>
    </div>

    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col">
        <q-toggle v-model="form.casa_lar" label="Casa lar" />
      </div>
      <div class="col">
        <q-toggle v-model="form.protecao_emergencia" label="Proteção em situação de Emergência" />
      </div>
      <div class="col">
        <q-toggle v-model="form.centro_acolhida" label="Centro de Acolhida" />
      </div>
    </div>

    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col">
        <q-input filled v-model="form.outro_servico" label="Outro serviço" :error-message="error?.errors?.outro_servico?.[0]" :error="error?.errors?.outro_servico?.length > 0"/>
      </div>
    </div>

    <q-separator spaced="xl" />

    <div class="row justify-end">
      <q-btn label="Salvar" type="submit" color="primary" :loading="pending"/>
    </div>
  </q-form>
</template>

<script>
import { ref, reactive } from 'vue'

import getModel from '@/composables/getModel';
import useCollection from '@/composables/useCollection';
import useNotify from '@/composables/useNotify';

export default {
  props: ['id'],
  setup (props, context) {
    const { data, load: resourceLoad, pending: resourcePending, error: resourceError } = getModel(`alunos/${props.id}/fichas/assistencias`);
    const { pending, error, create } = useCollection(`alunos/${props.id}/fichas/assistencias`);
    const { triggerPositive, triggerNegative } = useNotify();

    const form = reactive({
      cras: false,
      creas: false,
      acolhimento_institucional: false,
      casa_lar: false,
      protecao_emergencia: false,
      centro_acolhida: false,
      outro_servico: '',
    });

    resourceLoad().then(() => {
      Object.assign(form, data.value);
       if(data.value.length !== 0) {
         context.emit('check','servicos');
       }
    });

    const handleSubmit = async () => {
      const response = await create(form);
      if(response) {
        triggerPositive('Salvo com sucesso!');
         context.emit('check','servicos');
      } else {
        triggerNegative('Verifique se os campos estão corretos.');
      }
    }

    return {
      form, resourcePending, resourceError,
      pending, error,
      handleSubmit
    }
  }
}
</script>

<style>

</style>